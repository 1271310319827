const config = { 
   // apiUrl: 'https://dev.api.jurnixapp.tk:8080/',
    apiUrl: 'https://kodeon.pythonanywhere.com/',

    appVersion: 'v1.4.76',


    /**
     * DATA UPDATE FREQUENCY (in milliseconds) 
     * > Special Values
     * > -1 : Fetch once and don't update automatically
     * >  0 : Don't Fetch anything from backend
     */
    dataUpdateFrequency: -1,
};

export default config;
