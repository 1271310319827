import api from './';

const orderAPI = {
	fetch,
	fetchFromPage,
};

function fetch(id='') {
	const endpoint = `/order/alllistorder/${id}`;

	return api
		.get(endpoint)
		.then((response) => {
			console.log('orderapi', response);
			return response;
		})
		.catch((error) => {
			return error;
		});
}


function fetchFromPage(page='') {
	const endpoint = `/order/alllistorder/?page=${page}`;
	
	return api
		.get(endpoint)
		.then((response) => {
			console.log('orderapi', response);
			return response;
		})
		.catch((error) => {
			return error;
		});
}


export default orderAPI;
