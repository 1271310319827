import vehicleAPI from '../../api/vehicleAPI';

export const FETCH_VEHICLE_LIST = 'FETCH_VEHICLE_LIST';
export const FETCH_VEHICLE_LIST_BEGIN = 'FETCH_VEHICLE_LIST_BEGIN';
export const FETCH_VEHICLE_LIST_SUCCESS = 'FETCH_VEHICLE_LIST_SUCCESS';
export const FETCH_VEHICLE_LIST_FAILURE = 'FETCH_VEHICLE_LIST_FAILURE';
export const UPDATE_VEHICLE_LIST = 'UPDATE_VEHICLE_LIST';

export function fetchVehicleList() {
	return (dispatch) => {
		dispatch(fetchVehicleListBegin());
		return vehicleAPI
			.fetchVehicle()
			.then((response) => {
                if (response.data.errors) {
                    dispatch(fetchVehicleListFailure("api call exception"));
                } else {
                    dispatch(updateVehicleList(response.data.data));
                    dispatch(fetchVehicleListSuccess());
                }
				return response;
			})
			.catch((error) => {
				dispatch(fetchVehicleListFailure("api call error"));
				return error;
			});
	};
}

export function fetchVehicleListBegin() {
	return { type: FETCH_VEHICLE_LIST_BEGIN };
}

export function fetchVehicleListSuccess() {
	return { type: FETCH_VEHICLE_LIST_SUCCESS };
}

export function fetchVehicleListFailure(error) {
	return { type: FETCH_VEHICLE_LIST_FAILURE, data: error };
}

export function updateVehicleList(newList) {
	return { type: UPDATE_VEHICLE_LIST, data: newList };
}
