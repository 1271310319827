import React, { Component, useState } from 'react';
import { Link } from 'react-router-dom';
import Loader from '../../../../shared/components/Loader';

import './style.css';
import './search.css';


const TableHeader = ({ settings }) => {
	return (
		<div className="table-header uncopyable">
			{settings.columns.map((col, i) => {
				return (
					<div
						key={i}
						className={`table-header-data ${col.size && col.size > 1
							? `table-data-size-${col.size}`
							: ''} ${col.center ? 'center-both' : ''}`}
					>
						{col.name}
					</div>
				);
			})}
		</div>
	);
};

const TableBody = ({ settings, data, rowComponent, to, loading, statusKey, statusKeyReverse }) => {
	return loading ? (
		<div className="table-body center-both w-100 h-100">
			<Loader className="center-both" />
		</div>
	) : data.length > 0 ? (
		<div className="table-body scroll scroll scroll-light scroll-small">
			{data.map((item, i) => {
				const row = rowComponent ? (
					<rowComponent to={to} statusKey={statusKey} statusKeyReverse={statusKeyReverse} key={i} />
				) : (
						<TableRow item={item} settings={settings} idx={i} key={i} to={to} statusKey={statusKey} statusKeyReverse={statusKeyReverse} />
					);
				return row;
			})}
		</div>
	) : (
				<div className="table-body center-both w-100 h-100">
					<div className="theme-text rise-up-fade-in fill-mode-forwards">Found 0 Entries</div>
				</div>
			);
};

const TableRow = ({ item, idx, settings, to, statusKey, statusKeyReverse }) => {
	let statusColor = statusKey === undefined ? 'gray' : item[statusKey] ? 'green' : 'red';
	statusColor = (statusKeyReverse && statusColor === 'red') ? 'green' : (statusKeyReverse && statusColor === 'green') ? 'red' : statusColor;
	return (
		<Link to={to + String(item.id)} key={item.id}>
			<div
				className="table-row hover-rise rise-up-fade-in "
				key={item.id}
				style={{
					animationDelay: `${(idx + 1) * 100}ms`,
					borderLeftColor: `${statusColor}`,
				}}
				onAnimationEnd={(e) => {
					e.target.style.opacity = '1';
				}}
			>
				{settings.columns.map((col, i) => {
					return (
						<div
							key={String(item.id) + '_' + String(i)}
							className={`table-data ${col.size && col.size > 1
								? `table-data-size-${col.size}`
								: ''} ${col.center ? 'center-both' : ''}`}
						>
							{Array.isArray(item[col.data_name]) ? (
								item[col.data_name].map((element, idx) => {
									return (
										<div key={idx}>
											<br />
											{element}
										</div>
									);
								})
							) : typeof item[col.data_name] === 'boolean' ? (
								item[col.data_name] ? "YES" : "NO"
							) : (
										item[col.data_name] || item[col.default] || 'not availabe'
									)}
						</div>
					);
				})}
			</div>
		</Link>
	);
};

function SearchTable({ filterInput, handleFilterChange, placeholder, setFilterInput }) {
	const [isActive, setIsActive] = useState(false);

	return (
		<div className={`search-table ${isActive ? "active" : ""}`}>
			<span onClick={() => setIsActive(!isActive)} className={`icon ${isActive ? "pointer-events-none" : ""}`}>
				<span className="material-icons">
					search
				</span>
			</span>
			{isActive && <input
				value={filterInput}
				onChange={e => handleFilterChange(e.target.value)}
				placeholder={placeholder}
				onBlur={() => {
					if (!filterInput)
						setIsActive(false)
				}}
				ref={input => input && input.focus()}
			/>}

			{filterInput &&
				isActive && (
					<span onClick={() => handleFilterChange("")} className={`clear-input uncopyable`}>
						clear
					</span>
				)}
		</div>
	);
}

class Table extends Component {
	state = {
		q: '',
		data: this.props.data,
		settings: this.props.settings,
		filtered: null,
	};

	filter = (q) => {
		console.log('in filter')
		this.setState({
			q
		})
		if (q === '') {
			this.setState(
				{
					filtered: null,
				});
			return;
		}

		const data = this.props.data.filter(item => {
			for (let i = 0; i < this.state.settings.columns.length; ++i) {
				if (String(item[this.state.settings.columns[i].data_name]).toLowerCase().includes(q.toLowerCase())) {
					return true;
				}
			}
			return false;
		})

		this.setState({ filtered: data });
	}

	render() {
		const { settings, rowComponent, to, loading, statusKey, statusKeyReverse } = this.props;
		const data = this.state.filtered ? this.state.filtered : this.props.data;

		return (
			<div className="table">
				{settings && (
					<React.Fragment>
						<div className='search-container'>
							<SearchTable
								filterInput={this.state.q}
								handleFilterChange={q => this.filter(q)}
								placeholder='Search...'
							/>
							
						</div>
						<TableHeader settings={settings} />
						<TableBody
							rowComponent={rowComponent}
							settings={settings}
							data={data}
							to={to}
							loading={loading}
							statusKey={statusKey}
							statusKeyReverse={statusKeyReverse}
						/>
					</React.Fragment>
				)}
			</div>
		);
	}
}

export default Table;
