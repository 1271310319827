import { Cookies } from 'react-cookie';
import api from '../api/';

const authenticateService = {
	login,
	logout,
	isUserLoggedIn,
};

function isUserLoggedIn() {
	let cookies = new Cookies();
	let user = cookies.get('user');
	return user;
}

function login(phone, password) {
	const data = {
		phone,
		password,
	};

	const endpoint = `login/`;

	return api
		.post(endpoint, data, false, {})
		.then((response) => {
			const user = response.data;
			setSession(user);
			if (response.data.errors) {
				logout();
			}
			return response;
		})
		.catch((error) => {
			return error;
		});
}

function logout() {
	setSession();
}

function setSession(user) {
	let cookies = new Cookies();
	if (user) cookies.set('user', JSON.stringify(user), { path: '/' });
	else cookies.remove('user');
}

export default authenticateService;
