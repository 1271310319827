import {
	FETCH_ORDER_LIST,
	FETCH_ORDER_LIST_BEGIN,
	FETCH_ORDER_LIST_SUCCESS,
	FETCH_ORDER_LIST_FAILURE,
	UPDATE_ORDER_LIST,
} from '../action/orderAction';

// const orderList = [
// 	{
		
// 	},
// ];

const initialState = {
	list: [],
	listError: '',
	listFetchingInProgress: false,
};

function order(state = initialState, action) {
	switch (action.type) {
		case FETCH_ORDER_LIST:
			return {
				...state,
			};

		case FETCH_ORDER_LIST_BEGIN:
			return {
				...state,
				listFetchingInProgress: true,
			};

		case FETCH_ORDER_LIST_SUCCESS:
			return {
				...state,
				listFetchingInProgress: false,
			};

		case FETCH_ORDER_LIST_FAILURE:
			return {
				...state,
				listError: action.data,
				listFetchingInProgress: false,
			};

		case UPDATE_ORDER_LIST:
			return {
				...state,
				list: action.data,
			};

		default:
			return state;
	}
}

export default order;
