import React, { Component } from 'react';
//import { connect } from 'react-redux';

import Widget from '../../shared/Widget';
import './style.scss';
import api from '../../../../services/api';
import { withRouter } from 'react-router-dom';

class ReasonAdd extends Component {
  state = {
    user_type: '',
    reason_code: '',
    description: ''
  };

  componentDidMount() {
  }

  handleUserChange = (e) => {
    console.log(this.state)
    this.setState({ user_type: e.target.value })
  }


  handleSubmit = (e) => {
    e.preventDefault()
    const { user_type, reason_code, description } = this.state
    if (user_type && reason_code && description) {
      const body = {
        user_type: user_type,
        reason_code: reason_code,
        reason_description: description
      }
      console.log('body', body)
      api.post('order/cancellation_reason/', body).then(res => {
        console.log('res', res.data)
        this.props.history.push('/cancellation')
      })
      this.setState({ user_type: '', reason_code: '', reason_description: '' })
    }
  }

  render() {
    return (
      <Widget className="screen-not-widget" title="Add Cancellation Reason" size="wide" screenNotWidget={true}>
        <div className="reason-add">
          <div className="form rise-up-fade-in fill-mode-forwards scroll">
            <form className="vehicle-form" onSubmit={this.handleSubmit}>
              <div className="center-both">
                <label className="container">
                  Vendor
							<input
                    type="radio"
                    id="userType"
                    name="userType"
                    value="Vendor"
                    onClick={this.handleUserChange}
                    required
                  />
                  <span className="checkmark" />
                </label>
                <label className="container">
                  User
							<input
                    type="radio"
                    id="userType"
                    name="userType"
                    value="User"
                    onClick={this.handleUserChange}
                    required
                  />
                  <span className="checkmark" />
                </label>
              </div>
              <label className="hover-rise" htmlFor="type">
                Reason Code
							</label>
              <input
                className="hover-rise"
                type="text"
                id="code"
                onChange={(e) => this.setState({ reason_code: e.target.value })}
                required
              />
              <label className="hover-rise" htmlFor="maxWeight">
                Reason Description
							</label>
              <input
                className="hover-rise"
                type="text"
                id="description"
                onChange={(e) => this.setState({ description: e.target.value })}
                required
              />
              <hr />
              <div className="center-both">
                <input
                  type="submit"
                  className="center-both login-button add-more-button hover-rise"
                  id="submit"
                  value='Add Reason'
                  required
                />
              </div>
            </form>
          </div>
        </div>
      </Widget>
    );
  }
}

export default withRouter(ReasonAdd);
