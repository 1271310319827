import React, { Component } from 'react';
import { connect } from 'react-redux';

import Table from '../../shared/Table';
import Widget from '../../shared/Widget';

const SETTINGS_USER_CARD = {
	columns: [
		{
			name: 'Name',
			data_name: 'user_name',
			size: 2,
		},
		{
			name: 'Email',
			data_name: 'email',
			size: 2,
		},
		{
			name: 'Phone',
			data_name: 'phone',
			center: true,
		},
		{
			name: 'Date Joined',
			data_name: 'date_joined',
			center: true,
		},
	],
	table_name: 'Users',
	size: 'wide',
	profile_pic: 'profile_pic',
};

class User extends Component {
	render() {
		const { userList, userListFetchingInProgress } = this.props;
		return (
			<Widget className="screen-not-widget" title="User" size="wide" screenNotWidget={true}>
				<Table
					settings={SETTINGS_USER_CARD}
					data={userList}
					to="/user/"
					loading={userListFetchingInProgress}
					statusKey="phone_verified"
				/>
			</Widget>
		);
	}
}

function mapStateToProps(state) {
	return {
		userList: state.user.list,
		userListFetchingInProgress: state.user.listFetchingInProgress,
	};
}

export default connect(mapStateToProps)(User);
