import endUserAgreementAPI from '../../api/endUserAgreementAPI';

export const FETCH_END_USER_AGREEMENT_LIST = 'FETCH_END_USER_AGREEMENT_LIST';
export const FETCH_END_USER_AGREEMENT_LIST_BEGIN = 'FETCH_END_USER_AGREEMENT_LIST_BEGIN';
export const FETCH_END_USER_AGREEMENT_LIST_SUCCESS = 'FETCH_END_USER_AGREEMENT_LIST_SUCCESS';
export const FETCH_END_USER_AGREEMENT_LIST_FAILURE = 'FETCH_END_USER_AGREEMENT_LIST_FAILURE';
export const UPDATE_END_USER_AGREEMENT_LIST = 'UPDATE_END_USER_AGREEMENT_LIST';

export function fetchEndUserAgreementList() {
	return (dispatch) => {
		dispatch(fetchEndUserAgreementListBegin());
		return endUserAgreementAPI
			.fetch()
			.then((response) => {
                if (response.data.errors) {
                    dispatch(fetchEndUserAgreementListFailure("api call exception"));
                } else {
                    dispatch(updateEndUserAgreementList(response.data.data));
                    dispatch(fetchEndUserAgreementListSuccess());
                }
				return response;
			})
			.catch((error) => {
				dispatch(fetchEndUserAgreementListFailure("api call error"));
				return error;
			});
	};
}

export function fetchEndUserAgreementListBegin() {
	return { type: FETCH_END_USER_AGREEMENT_LIST_BEGIN };
}

export function fetchEndUserAgreementListSuccess() {
	return { type: FETCH_END_USER_AGREEMENT_LIST_SUCCESS };
}

export function fetchEndUserAgreementListFailure(error) {
	return { type: FETCH_END_USER_AGREEMENT_LIST_FAILURE, data: error };
}

export function updateEndUserAgreementList(newList) {
	return { type: UPDATE_END_USER_AGREEMENT_LIST, data: newList };
}
