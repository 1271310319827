import React, { Component } from 'react';

// import { NotificationButton, SearchButton, AvatarButton } from '../Header/components/HeaderButtons';
import config from '../../../../config';
import './style.css';


// const HelpButton = () => {
//     return (
//         <div className="uncopyable avatar-button header-button center-both"><i className="material-icons md-light ">
//         help</i></div>
//     );
// }

const AppVersion = () => {
    return (
        <div className="uncopyable rise-up-fade-in fill-mode-forwards center-both version-text">{config.appVersion}</div>
    );
}

const FooterNav = () => {
	return (
		<nav className="header-nav-container">
			<ul className="header-nav">
				<li className="header-nav-item">
					{/* <HelpButton /> */}
					<AppVersion/>
				</li>
			</ul>
		</nav>
	);
};

class Footer extends Component {
	render() {
		return (
			<div className="footer">
				<FooterNav />
			</div>
		);
	}
}

export default Footer;
