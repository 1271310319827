import React, { Component } from 'react';
import { connect } from 'react-redux';

import Loader from '../../../../../shared/components/Loader';
import faqApI from '../../../../../services/api/faqAPI';
import Table from '../../../shared/Table';

import './style.css';
import './radioButton.css';

const SETTINGS_FAQ_TABLE = {
	columns: [
		{
			name: 'question',
			data_name: 'question',
		},
		{
			name: 'answer',
			data_name: 'answer',
		},
	]
};

class FAQAdd extends Component {
	state = {
		question: '',
		answer: '',
		faqFor: null,
		faqAddSuccess: false,
		faqAddFail: false,
		sending: false,
	};

	resetForm = () => {
		this.setState({
			question: '',
			answer: '',
			faqAddSuccess: false,
			faqAddFail: false,
			sending: false,
		});
	};

	addFAQ = () => {
		const data = {
			question: this.state.question,
			answer: this.state.answer,
			for_vendor: this.state.faqFor === 'vendor' ? true : false,
		};

		faqApI
			.create(data)
			.then((response) => {
				if (response.errors) {
					this.setState({
						loading: false,
						faqAddFail: true,
					});
				}
				else {
					this.setState({
						loading: false,
						faqAddSuccess: true,
					});
				}
			})
			.catch((error) => {
				this.setState({
					loading: false,
					faqAddFail: true,
				});
			});
	};

	handleSubmit = (e) => {
		e.preventDefault();
		this.setState({
			sending: true,
			faqAddFail: false,
		});
		this.addFAQ();
	};

	handleChange = (e) => {
		console.log(e.target.value);
		// e.target.checked = 'checked';
		this.setState({
			[e.target.id]: e.target.value,
		});
	};

	handleAddFAQ = (e) => {
		e.preventDefault();
		this.resetForm();
		console.log('handled add faq');
	};

	render() {
		const { question, answer, faqAddFail, faqAddSuccess, sending } = this.state;
		return (
			<div className="form w-100 h-100 rise-up-fade-in fill-mode-forwards scroll">
				{!faqAddSuccess && (
					<form className="policy-form vehicle-form" onSubmit={this.handleSubmit}>
						<label className="hover-rise" htmlFor="question">
							Question
						</label>
						<textarea
							className="hover-rise scroll"
							type="text"
							id="question"
							value={question}
							onChange={this.handleChange}
							maxlength="10"
							required
						/>

						<label className="hover-rise">Answer</label>
						<textarea
							className="hover-rise scroll"
							type="text"
							id="answer"
							value={answer}
							onChange={this.handleChange}
							required
						/>

						<label className="container">
							Vendor
							<input
								type="radio"
								id="faqFor"
								name="faqFor"
								value="vendor"
								onClick={this.handleChange}
								// onChange={this.handleChange}
								required
							/>
							<span className="checkmark" />
						</label>
						<label className="container">
							User
							<input
								type="radio"
								id="faqFor"
								name="faqFor"
								value="user"
								onClick={this.handleChange}
								// onChange={this.handleChange}
								required
							/>
							<span className="checkmark" />
						</label>

						{/* <label> */}
						{sending && <Loader />}
						{faqAddFail && (
							<div className="vehicle-button-area center-both">
								<p className="center-both fail-text hover-rise fall-down-fade-in fill-mode-forwards text-center ">
									Vehicle Not Added <span class="material-icons center-both">check</span>
								</p>
							</div>
						)}
						<div className="center-both">
							{/* </label> */}

							<input
								type="submit"
								className="center-both login-button add-more-button hover-rise"
								id="submit"
								value={sending ? 'Adding' : faqAddFail ? 'Retry' : 'Add FAQ'}
								required
							/>
						</div>
					</form>
				)}

				{faqAddSuccess && (
					<div className="center-both h-100">
						{/* </label> */}
						<div>
							<div className="vehicle-button-area center-both">
								<p className="center-both success-text hover-rise fall-down-fade-in fill-mode-forwards text-center ">
									FAQ added successfully <span class="material-icons center-both">check</span>
								</p>
							</div>
							<div className="vehicle-button-area">
								<div
									className="center-both add-more-button hover-rise  rise-up-fade-in fill-mode-forwards"
									onClick={this.handleAddFAQ}
								>
									Add Another
								</div>
							</div>
						</div>
					</div>
				)}
			</div>
		);
	}
}

const FAQ = ({ faqList, faqListFetchingInProgress }) => {
	return (
		<div className="policy-tab-content block-container">
			<div className="block">
				<div className="detail-heading">Add FAQ</div>
				<FAQAdd />
			</div>
			<div className="block">
				<div className="detail-heading">FAQs</div>
				<Table
					settings={SETTINGS_FAQ_TABLE}
					data={faqList}
					loading={faqListFetchingInProgress}
					statusKey="id"
				/>
			</div>
		</div>
	);
};

function mapStateToProps(state) {
	return {
		faqList: state.faq.list,
		faqListFetchingInProgress: state.faq.listFetchingInProgress,
	};
}

export default connect(mapStateToProps)(FAQ);
