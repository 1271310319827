import React from 'react';

import './style.css';

const NotificationButton = () => {
    return (
        <div className="uncopyable notification-button header-button center-both"><i className="material-icons md-light ">notifications_none</i></div>
    );
}

const SearchButton = () => {
    return (
        <div className="uncopyable search-button header-button center-both"><i className="material-icons md-light ">search</i></div>
    );
}

const AvatarButton = () => {
    return (
        <div className="uncopyable avatar-button header-button center-both"><i className="material-icons md-light ">perm_identity</i></div>
    );
}

export { NotificationButton , SearchButton , AvatarButton };