import React, { Component } from 'react';

import Widget from '../../shared/Widget';

import UserAPI from '../../../../services/api/userAPI';

import './style.css';

const DetailBlock = ({ heading, items }) => {
	return (
		<div className="block">
			<div className="detail-block">
				<div className="detail-heading">{heading}</div>
				{items.map((item, idx) => {
					if (item.image) {
						return (
							<div key={idx} className={`detail-item center-both hover-rise`}>
								<img width={64} height={64} src={item.src} alt="vendor profile" />
							</div>
						);
					}
					else {
						return (
							<div key={idx} className="detail-item hover-rise">
								<span className="detail-label">{item.label}</span>
								<span className="detail-text">{item.text}</span>
							</div>
						);
					}
				})}
			</div>
		</div>
	);
};

class UserDetail extends Component {
	state = {
		detail: {},
		id: this.props.match.params.id,
		loading: true,
	};

	componentDidMount() {
		this.fetchUserDetail();
	}

	fetchUserDetail() {
		UserAPI.fetchUser(this.state.id).then((res) => {
			this.setState({
				detail: res.data,
				loading: false,
			});
		});
	}

	render() {
		const {
			user_name,
			first_name,
			last_name,
			country_code,
			phone,
			phone_verified,
			email,
			email_verified,
			date_joined,
			is_active,
			is_staff,
			is_superuser,
			is_vendor,
			last_login,
			groups,
			alive,
		} = this.state.detail;
		return (
			<Widget
				className="screen-not-widget"
				title="User Detail"
				size="wide"
				screenNotWidget={true}
				loading={this.state.loading}
			>
				{/* <div className="detail-screen"> */}
				<div className="block-container scroll">
					<DetailBlock
						heading="profile"
						items={[
							{
								label: 'username',
								text: user_name || 'Not Available',
							},
							{
								label: 'Name',
								text: first_name ? `${first_name} ${last_name}` : 'Not Available',
							},
							{
								label: 'Phone',
								text: phone ? `${country_code} ${phone} ${phone_verified
									? '(verified)'
									: '(not verified)'}` : 'Not Available',
							},
							{
								label: 'Email',
								text: email ? `${email} ${email_verified ? '(verified)' : '(not verified)'}` : 'Not Available',
							},
						]}
					/>



					<div className="block">
						<div className="detail-block">
							<div className="detail-heading">roles</div>

							<div className="detail-item hover-rise">
								<span className="detail-label">active</span>
								<span className={`detail-text ${is_active ? 'success-text' : 'fail-text'}`}>{`${is_active ? 'Yes' : 'No'}`}</span>
							</div>

							<div className="detail-item hover-rise">
								<span className="detail-label">staff</span>
								<span className={`detail-text ${is_staff ? 'success-text' : 'fail-text'}`}>{`${is_staff ? 'Yes' : 'No'}`}</span>
							</div>

							<div className="detail-item hover-rise">
								<span className="detail-label">superuser</span>
								<span className={`detail-text ${is_superuser ? 'success-text' : 'fail-text'}`}>{`${is_superuser ? 'Yes' : 'No'}`}</span>
							</div>

							<div className="detail-item hover-rise">
								<span className="detail-label">vendor</span>
								<span className={`detail-text ${is_vendor ? 'success-text' : 'fail-text'}`}>{`${is_vendor ? 'Yes' : 'No'}`}</span>
							</div>
						</div>
					</div>

					<DetailBlock
						heading="misc"
						items={[
							{
								label: 'Date Joined',
								text: date_joined || 'Not Available',
							},
							{
								label: 'Last Login',
								text: last_login || 'Not Available',
							},
							{
								label: 'Alive',
								text: alive ? `${alive ? 'Yes' : 'No'}` : 'Not Available',
							},
							{
								label: 'Groups',
								text: groups ? `${groups.length === 0 ? 'None' : groups.join(', ')}` : 'Not Available',
							},
						]}
					/>
				</div>
				{/* </div> */}
			</Widget>
		);
	}
}

export default UserDetail;
