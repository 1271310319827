import api from './';
const endUserAgreementAPI = {
	fetch,
	create,
	update,
	updatePartial,
	delete_,
	setContent,
};

function fetch(id = '') {
	const endpoint = `/extras/getenduseragreement/${id}`;

	return api
		.get(endpoint)
		.then((response) => {
			return response;
		})
		.catch((error) => {
			return error;
		});
}

function setContent(id = '', data) {
	const endpoint = `/extras/getenduseragreement/${id}`;

	return api
		.post(endpoint, data)
		.then((response) => {
			return response;
		})
		.catch((error) => {
			return error;
		});
}

function create(data = null) {
	const endpoint = `/extras/getenduseragreement/`;

	return api
		.post(endpoint, data)
		.then((response) => {
			return response;
		})
		.catch((error) => {
			return error;
		});
}

function update(id = null, data = null) {
	const endpoint = `/extras/getenduseragreement/${id}`;

	return api
		.put(endpoint, data)
		.then((response) => {
			return response;
		})
		.catch((error) => {
			return error;
		});
}

function updatePartial(id = null, data = null) {
	const endpoint = `/extras/getenduseragreement/${id}`;

	return api
		.patch(endpoint, data)
		.then((response) => {
			return response;
		})
		.catch((error) => {
			return error;
		});
}

function delete_(id = null) {
	const endpoint = `/extras/getenduseragreement/${id}`;

	return api
		.delete_(endpoint)
		.then((response) => {
			return response;
		})
		.catch((error) => {
			return error;
		});
}

export default endUserAgreementAPI;
