import React, {  useMemo } from "react";
// import TableToolbar from "./TableToolbar";
import PaginationToolbar from "./PaginationToolbar";
import {Link} from "react-router-dom";
import "./table.scss";

// const Avatar = ({ src, alt }) => (
//   <span className="avatar">
//     <img src={src} alt={alt} />
//   </span>
// );

// const COLUMN = [
//   {
//     name: "name",
//     dataKey: "fullname",
//     size: 2,
//   },
//   {
//     name: "phone",
//     dataKey: "phone_no_registered",
//     size: 1,
//   },
//   {
//     name: "gender",
//     dataKey: "gender",
//     size: 1,
//   },
// ];

// const COL_COMPONENT = () => {
//   return (
//     <tr>
//       <td style={{ flex: 1 }}></td>
//       <td style={{ flex: 2 }}>name</td>
//       <td style={{ flex: 1 }}>phone</td>
//       <td style={{ flex: 1 }}>gender</td>
//     </tr>
//   );
// };

// /

function TableHeader({ children, columns, component }) {
  if (component) return <thead>{component()}</thead>;

  return (
    <thead>
      {
        <tr>
          {columns.map(({ name, dataKey, size }) => {
            return (
              <td key={name} style={{ flex: size }}>
                {name}
              </td>
            );
          })}
        </tr>
      }
    </thead>
  );
}

function TableBody({ columns = [], data = [], component, dataLoading,rowLink, rowLinkAccessor }) {
  if (dataLoading)
    return (
      <tbody>
        {[{}, {}, {}, {}, {}].map((r, i) => component(r, i, dataLoading))}
      </tbody>
    );

  if (component)
    return <tbody>{data.map((r, i) => component(r, i, dataLoading))}</tbody>;

  return (
    <tbody>
      {data.map((r) => {
        return (
          <Link to={`${rowLink}${rowLinkAccessor}`} >
          <tr key={r.id}>
            {columns.map(({ name, dataKey, size }) => {
              return (
                <td key={r.id + name} style={{ flex: size }}>
                  {r[dataKey]}
                </td>
              );
            })}
          </tr>
          </Link>
        );
      })}
    </tbody>
  );
}

export default function Table({
  columns,
  data,
  rowComponent,
  headerComponent,
  gridViewEnabled,
  currentPage,
  pageCount,
  setCurrentPage,
  // searchQuery,
  // setSearchQuery,
  dataLoading,
  rowLink,
  rowLinkAccessor,
}) {
  columns = useMemo(() => columns, [columns]);

  // if (!searchQuery && !setSearchQuery) {
  //     const [searchQuery, setSearchQuery] = useState('');
  // }

  // const [gridView, setGridView] = useState(gridViewEnabled);
  // let setcurrentPage;
  // [currentPage, setcurrentPage] = useState(currentPage);

  return (
    <div className="table-container">
      {/* <TableToolbar
        searchQuery={searchQuery}
        handleQueryChange={setSearchQuery}
        placeholder="search"
        gridView={gridView}
        handleGridView={setGridView}
      /> */}

      <table className="custom-table scroll">
        <TableHeader columns={columns} component={headerComponent} />

        <TableBody
          data={data}
          columns={columns}
          component={rowComponent}
          dataLoading={dataLoading}
          rowLink={rowLink}
          rowLinkAccessor={rowLinkAccessor}
        />
      </table>

      <PaginationToolbar
        currentPage={currentPage}
        pageCount={pageCount}
        handlePageChange={setCurrentPage}
      />
    </div>
  );
}
