const SETTINGS_VENDOR_CARD = {
	columns: [
		{
			name: 'Name',
			data_name: 'fullname',
			size: 2,
		},
		{
			name: 'Phone',
			data_name: 'phone_no_registered',
			center: true,
		},
		{
			name: 'National ID',
			data_name: 'national_id_number',
			center: true,
		},
	],
	table_name: 'Vendors',
	size: 'wide',
};

const SETTINGS_USER_CARD = {
	columns: [
		{
			name: 'Name',
			data_name: 'user_name',
			size: 2,
		},
		{
			name: 'Email',
			data_name: 'email',
			size: 3,
		},
		{
			name: 'Phone',
			data_name: 'phone',
			center: true,
		},
	],
	table_name: 'Users',
	// size: 'wide',
};

const SETTINGS_VEHICLE_CARD = {
	columns: [
		{
			name: 'Type',
			data_name: 'vichel_type',
			size: 2,
		},
		{
			name: 'Volume',
			data_name: 'max_volume',
			center: true,
		},
		{
			name: 'Capacity',
			data_name: 'max_weight_capacity',
			center: true,
		},
		{
			name: 'Charge',
			data_name: 'per_km_charges',
			center: true,
		},
	],
	table_name: 'Vehicles',
	size: 'wide',
};

const SETTINGS_NOTIFICATION_CARD = {
	columns: [
		{
			name: '#',
			data_name: 'text',
			// size: 3,
		},
		{
			name: '',
			data_name: 'subText',
			// center: true,
		},
	],
	table_name: 'Messages',
	size: 'tall',
};

const SETTINGS_ORDER_CARD= {
	columns: [
		{
			name: 'Sender',
			data_name: 'sender_name',
			size: 2,
		},
		{
			name: 'type',
			data_name: 'type_of_delivery',
			center: true,
		},
		{
			name: 'Date Created',
			data_name: 'creation_time',
			center: true,
		},
		{
			name: 'Accuracy',
			data_name: 'accuracy',
		},
	],
	table_name: 'Orders',
	size: 'wide',
};

const SETTINGS_VERSION_LOG = {
	columns: [
		{
			name: 'Changes',
			data_name: 'log',
			size: 3,
		},
		{
			name: 'Version',
			data_name: 'version',
			// size: 3,
		},
		{
			name: 'Date',
			data_name: 'updateDate',
			// center: true,
		},
	],
	table_name: 'Version Log',
	size: 'tall',
};

export {
	SETTINGS_VERSION_LOG,
	SETTINGS_NOTIFICATION_CARD,
	SETTINGS_USER_CARD,
	SETTINGS_VEHICLE_CARD,
	SETTINGS_VENDOR_CARD,
	SETTINGS_ORDER_CARD,
};
