import api from './';

const priceDetailAPI = {
    sendData,
};

function sendData(data) {
	const endpoint = `/order/price_detail/`;

	return api
		.post(endpoint, data)
		.then((response) => {
			return response;
		})
		.catch((error) => {
			return error;
		});
}

export default priceDetailAPI;
