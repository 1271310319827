import React, { useState } from 'react';
import './paginationToolbar.scss';


export default function PaginationToolbar({ currentPage, pageCount, handlePageChange }) {
    // let setPageChange;
    const [currentPageL, setPageChangeL] = useState(currentPage);

    return (
        <div className='pagination-toolbar-container'>
            <div className='pagination-toolbar'>
                <div>
                    {
                        currentPage > 1 &&
                        <button
                            onClick={(e) => {
                                e.preventDefault();
                                handlePageChange(currentPage - 1)
                                setPageChangeL(currentPage - 1)
                            }}
                        >previous
                        </button>
                    }
                </div>

                <div>
                    page
                    <input
                        type="number"
                        value={currentPage}
                        onChange={(e) => {
                            e.preventDefault();
                            let num = e.target.value;
                            setPageChangeL(num);
                        }}
                        onBlur={(e) => {
                            let num = currentPageL;
                            let valid = num <= 1 ? 1 : num >= pageCount ? pageCount : num;
                            // console.log("v", valid, num);
                            setPageChangeL(valid);
                            handlePageChange(valid)
                        }}
                    />
                     of <span>{pageCount}</span>
                </div>

                <div>
                    {currentPage < pageCount &&
                        <button
                            onClick={(e) => {
                                e.preventDefault();
                                handlePageChange(currentPage + 1)
                                setPageChangeL(currentPage + 1)
                            }}
                        >
                            next
                        </button>
                    }
                </div>
            </div>
        </div >
    );
}