import api from '.';

const vendorAPI = {
	fetch,
	fetchDetail,
	verify,
};

function verify(id = "", detail = {}, verficationOptions) {
	const endpoint = `vendor/vendorverification/${id}`;

	const data = {
		"vendor": {
			"national_id_verified": verficationOptions.verifyNationalID,
			"national_id_admin_comm": verficationOptions.verifyNationalIDComment,
			"vendor_verified": true

		},
		"vichel_document_verified": verficationOptions.verifyVehicleDocument,
		"vichel_document_admin_comm": verficationOptions.verifyVehicleDocumentComment,
		"driving_licance_admin_comm": verficationOptions.verifyDrivingLicenseComment,
		"driving_licance_document_verified": verficationOptions.verifyDrivingLicense,
	};

	console.log("payload",data);
	return api
	    // .get(endpoint, {}, data)
		.patch(endpoint, data)
		.then((response) => {
			return response;
		})
		.catch((error) => {
			console.log(error);
			return error;
		});
}

function fetch(id = "") {
	const endpoint = `vendor/getvendor/${id}`;

	return api
		.get(endpoint)
		.then((response) => {
			return response;
		})
		.catch((error) => {
			return error;
		});
}

function fetchDetail(id = null) {
	const endpoint = `vendor/allvendorinfo/${id ? id : ''}`;

	return api
		.get(endpoint)
		.then((response) => {
			return response;
		})
		.catch((error) => {
			return error;
		});
}

export default vendorAPI;
