import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {connect} from 'react-redux';

import Table from '../../shared/Table';
import Widget from '../../shared/Widget';

import './style.css';

const SETTINGS_VEHICLE_CARD = {
	columns: [
		{
			name: 'Type',
			data_name: 'vichel_type',
			size: 2,
		},
		{
			name: 'Volume',
			data_name: 'max_volume',
			center: true,
		},
		{
			name: 'Capacity',
			data_name: 'max_weight_capacity',
			center: true,
		},
		{
			name: 'Base Fare',
			data_name: 'base_fare',
			center: true,
		},
		{
			name: 'Cost/kg',
			data_name: 'cost_per_kg',
			center: true,
		},
		{
			name: 'Charge/km',
			data_name: 'per_km_charges',
			center: true,
		},
	],
	table_name: 'Vehicles',
	size: 'wide',
	profile_pic: 'profile_pic',
};

class Vehicle extends Component {

	render() {
		const { vehicleList, vehicleListFetchingInProgress } = this.props;
		return (
			<React.Fragment>
				<Link to="./vehicle/new">
					<div className="center-both">
						<div className="center-both all-vehicle-button hover-rise  rise-up-fade-in fill-mode-forwards">
							Add Vehicle
						</div>
					</div>
				</Link>

				<Widget className="screen-not-widget" title="Vehicle" size="wide" screenNotWidget={true}>
					<Table
						settings={SETTINGS_VEHICLE_CARD}
						data={vehicleList}
						to="/vehicle/"
						loading={vehicleListFetchingInProgress}
					/>
				</Widget>
			</React.Fragment>
		);
	}
}

function  mapStateToProps(state) {
	return {
		vehicleList: state.vehicle.list,
		vehicleListFetchingInProgress: state.vehicle.listFetchingInProgress,
	};
}

export default connect(mapStateToProps)(Vehicle);
