import React, { Component } from 'react';

import { NotificationButton, SearchButton, AvatarButton } from './components/HeaderButtons';

import './style.css';

const HeaderNav = () => {
	return (
		<nav className="header-nav-container">
			<ul className="header-nav">
				<li className="header-nav-item">
					<NotificationButton />
				</li>
				<li className="header-nav-item">
					<SearchButton />
				</li>
				<li className="header-nav-item">
					<AvatarButton />
				</li>
			</ul>
		</nav>
	);
};

class Header extends Component {
	render() {
		return (
			<div className="header  fall-down-fade-in fill-mode-forwards">
				<HeaderNav />
			</div>
		);
	}
}

export default Header;
