import api from './';

const vehicleAPI = {
	fetchVehicle,
	addVehicle,
	editVehicle
};

function addVehicle({
	icon,
	type,
	maxWeight,
	maxVolume,
	baseFare,
	chargePerKM,
	costPerKG,
	chargePerMin,
	chargePerVolume,
	max_volume_in_one_hour,
	max_dist_in_one_hour,
	max_weight_in_one_hour,
	max_weight_in_three_hour,
	max_dist_in_three_hour,
	max_volume_in_three_hour,
	max_weight_in_same_day,
	max_dist_in_same_day,
	max_volume_in_same_day,
	max_weight_in_24_hour,
	max_dist_in_24_hour,
	max_volume_in_24_hour,
	max_weight_in_hrs24to2Days,
	max_dist_in_hrs24to2Days,
	max_volume_in_hrs24to2Days,
	max_weight_in_2_to_7_day,
	max_dist_in_2_to_7_day,
	max_volume_in_2_to_7_day,
	max_weight_in_seven_day_to_14,
	max_dist_in_seven_day_to_14,
	max_volume_in_seven_day_to_14,
	max_weight_in_more_then_17_days,
	max_dist_in_more_then_17_days,
	max_volume_in_more_then_17_days,
}) {
	const endpoint = `vendor/getadminaddedvichel/`;

	let data = new FormData();
	data.append('icon', icon);
	data.append('vichel_type', type);
	data.append('max_weight_capacity', maxWeight);
	data.append('max_volume', maxVolume);
	data.append('base_fare', baseFare);
	data.append('per_km_charges', chargePerKM);
	data.append('cost_per_kg', costPerKG);
	data.append('cost_per_min', chargePerMin);
	data.append('per_volume_charge', chargePerVolume);

	data.append('max_volume_in_one_hour', max_volume_in_one_hour)
	data.append('max_dist_in_one_hour', max_dist_in_one_hour)
	data.append('max_weight_in_one_hour', max_weight_in_one_hour)
	data.append('max_weight_in_three_hour', max_weight_in_three_hour)
	data.append('max_dist_in_three_hour', max_dist_in_three_hour)
	data.append('max_volume_in_three_hour', max_volume_in_three_hour)
	data.append('max_weight_in_same_day', max_weight_in_same_day)
	data.append('max_dist_in_same_day', max_dist_in_same_day)
	data.append('max_volume_in_same_day', max_volume_in_same_day)
	data.append('max_weight_in_24_hour', max_weight_in_24_hour)
	data.append('max_dist_in_24_hour', max_dist_in_24_hour)
	data.append('max_volume_in_24_hour', max_volume_in_24_hour)
	data.append('max_weight_in_hrs24to2Days', max_weight_in_hrs24to2Days)
	data.append('max_dist_in_hrs24to2Days', max_dist_in_hrs24to2Days)
	data.append('max_volume_in_hrs24to2Days', max_volume_in_hrs24to2Days)
	data.append('max_weight_in_2_to_7_day', max_weight_in_2_to_7_day)
	data.append('max_dist_in_2_to_7_day', max_dist_in_2_to_7_day)
	data.append('max_volume_in_2_to_7_day', max_volume_in_2_to_7_day)
	data.append('max_weight_in_seven_day_to_14', max_weight_in_seven_day_to_14)
	data.append('max_dist_in_seven_day_to_14', max_dist_in_seven_day_to_14)
	data.append('max_volume_in_seven_day_to_14', max_volume_in_seven_day_to_14)
	data.append('max_weight_in_more_then_17_days', max_weight_in_more_then_17_days)
	data.append('max_dist_in_more_then_17_days', max_dist_in_more_then_17_days)
	data.append('max_volume_in_more_then_17_days', max_volume_in_more_then_17_days)

	return api
		.post(endpoint, data, true)
		.then((res) => {
			return res;
		})
		.catch((error) => {
			return error;
		});
}

function editVehicle({
	id,
	icon,
	fileChange,
	type,
	maxWeight,
	maxVolume,
	baseFare,
	chargePerKM,
	costPerKG,
	chargePerMin,
	chargePerVolume,
	max_volume_in_one_hour,
	max_dist_in_one_hour,
	max_weight_in_one_hour,
	max_weight_in_three_hour,
	max_dist_in_three_hour,
	max_volume_in_three_hour,
	max_weight_in_same_day,
	max_dist_in_same_day,
	max_volume_in_same_day,
	max_weight_in_24_hour,
	max_dist_in_24_hour,
	max_volume_in_24_hour,
	max_weight_in_hrs24to2Days,
	max_dist_in_hrs24to2Days,
	max_volume_in_hrs24to2Days,
	max_weight_in_2_to_7_day,
	max_dist_in_2_to_7_day,
	max_volume_in_2_to_7_day,
	max_weight_in_seven_day_to_14,
	max_dist_in_seven_day_to_14,
	max_volume_in_seven_day_to_14,
	max_weight_in_more_then_17_days,
	max_dist_in_more_then_17_days,
	max_volume_in_more_then_17_days,
}) {
	const endpoint = `vendor/getadminaddedvichel/${id}/`;
	if (fileChange) {
		let data = new FormData();
		data.append('icon', icon);
		data.append('vichel_type', type);
		data.append('max_weight_capacity', maxWeight);
		data.append('max_volume', maxVolume);
		data.append('base_fare', baseFare);
		data.append('per_km_charges', chargePerKM);
		data.append('cost_per_kg', costPerKG);
		data.append('cost_per_min', chargePerMin);
		data.append('per_volume_charge', chargePerVolume);
		data.append('max_volume_in_one_hour', max_volume_in_one_hour)
		data.append('max_dist_in_one_hour', max_dist_in_one_hour)
		data.append('max_weight_in_one_hour', max_weight_in_one_hour)
		data.append('max_weight_in_three_hour', max_weight_in_three_hour)
		data.append('max_dist_in_three_hour', max_dist_in_three_hour)
		data.append('max_volume_in_three_hour', max_volume_in_three_hour)
		data.append('max_weight_in_same_day', max_weight_in_same_day)
		data.append('max_dist_in_same_day', max_dist_in_same_day)
		data.append('max_volume_in_same_day', max_volume_in_same_day)
		data.append('max_weight_in_24_hour', max_weight_in_24_hour)
		data.append('max_dist_in_24_hour', max_dist_in_24_hour)
		data.append('max_volume_in_24_hour', max_volume_in_24_hour)
		data.append('max_weight_in_hrs24to2Days', max_weight_in_hrs24to2Days)
		data.append('max_dist_in_hrs24to2Days', max_dist_in_hrs24to2Days)
		data.append('max_volume_in_hrs24to2Days', max_volume_in_hrs24to2Days)
		data.append('max_weight_in_2_to_7_day', max_weight_in_2_to_7_day)
		data.append('max_dist_in_2_to_7_day', max_dist_in_2_to_7_day)
		data.append('max_volume_in_2_to_7_day', max_volume_in_2_to_7_day)
		data.append('max_weight_in_seven_day_to_14', max_weight_in_seven_day_to_14)
		data.append('max_dist_in_seven_day_to_14', max_dist_in_seven_day_to_14)
		data.append('max_volume_in_seven_day_to_14', max_volume_in_seven_day_to_14)
		data.append('max_weight_in_more_then_17_days', max_weight_in_more_then_17_days)
		data.append('max_dist_in_more_then_17_days', max_dist_in_more_then_17_days)
		data.append('max_volume_in_more_then_17_days', max_volume_in_more_then_17_days)

		return api
			.patch(endpoint, data, true)
			.then((res) => {
				return res;
			})
			.catch((error) => {
				return error;
			});
	}
	let data = new FormData();
	data.append('vichel_type', type);
	data.append('max_weight_capacity', maxWeight);
	data.append('max_volume', maxVolume);
	data.append('base_fare', baseFare);
	data.append('per_km_charges', chargePerKM);
	data.append('cost_per_kg', costPerKG);
	data.append('cost_per_min', chargePerMin);
	data.append('per_volume_charge', chargePerVolume);
	data.append('max_volume_in_one_hour', max_volume_in_one_hour)
	data.append('max_dist_in_one_hour', max_dist_in_one_hour)
	data.append('max_weight_in_one_hour', max_weight_in_one_hour)
	data.append('max_weight_in_three_hour', max_weight_in_three_hour)
	data.append('max_dist_in_three_hour', max_dist_in_three_hour)
	data.append('max_volume_in_three_hour', max_volume_in_three_hour)
	data.append('max_weight_in_same_day', max_weight_in_same_day)
	data.append('max_dist_in_same_day', max_dist_in_same_day)
	data.append('max_volume_in_same_day', max_volume_in_same_day)
	data.append('max_weight_in_24_hour', max_weight_in_24_hour)
	data.append('max_dist_in_24_hour', max_dist_in_24_hour)
	data.append('max_volume_in_24_hour', max_volume_in_24_hour)
	data.append('max_weight_in_hrs24to2Days', max_weight_in_hrs24to2Days)
	data.append('max_dist_in_hrs24to2Days', max_dist_in_hrs24to2Days)
	data.append('max_volume_in_hrs24to2Days', max_volume_in_hrs24to2Days)
	data.append('max_weight_in_2_to_7_day', max_weight_in_2_to_7_day)
	data.append('max_dist_in_2_to_7_day', max_dist_in_2_to_7_day)
	data.append('max_volume_in_2_to_7_day', max_volume_in_2_to_7_day)
	data.append('max_weight_in_seven_day_to_14', max_weight_in_seven_day_to_14)
	data.append('max_dist_in_seven_day_to_14', max_dist_in_seven_day_to_14)
	data.append('max_volume_in_seven_day_to_14', max_volume_in_seven_day_to_14)
	data.append('max_weight_in_more_then_17_days', max_weight_in_more_then_17_days)
	data.append('max_dist_in_more_then_17_days', max_dist_in_more_then_17_days)
	data.append('max_volume_in_more_then_17_days', max_volume_in_more_then_17_days)

	return api
		.patch(endpoint, data, true)
		.then((res) => {
			return res;
		})
		.catch((error) => {
			return error;
		});
}

function fetchVehicle(id = '') {
	const endpoint = `vendor/getadminaddedvichel/${id}`;

	return api
		.get(endpoint)
		.then((response) => {
			return response;
		})
		.catch((error) => {
			return error;
		});
}

export default vehicleAPI;
