import React, { Component } from 'react';
import { connect } from 'react-redux';

import Table from '../../shared/Table';
import Widget from '../../shared/Widget';
import api from '../../../../services/api';

import './style.css';

const CONTACT_US_CARD = {
  columns: [
    {
      name: 'First Name',
      data_name: 'first_name',
      center: true,
    },
    {
      name: 'Last Name',
      data_name: 'last_name',
      center: true,
    },
    {
      name: 'Content',
      data_name: 'content',
      center: true,
    },
    {
      name: 'Mobile',
      data_name: 'mobile',
      center: true,
    },
    {
      name: 'City',
      data_name: 'city',
      center: true,
    },
    {
      name: 'State',
      data_name: 'state',
      center: true,
    },
  ],
  table_name: 'Contact Us',
  size: 'wide',
  profile_pic: 'profile_pic',
};

class ContactUs extends Component {
  state = {
    contactList: []
  }
  componentDidMount = () => {
    api.get('extras/getcontactus/?for=user').then(res => {
      this.setState({ contactList: res.data.data })
      console.log('res', res.data.data)
    })
  }

  render() {
    const { contactList } = this.state
    return (
      <Widget className="screen-not-widget" title="Contact Us" size="wide" screenNotWidget={true}>
        <Table
          settings={CONTACT_US_CARD}
          data={contactList}
          to="/contact-us/"
        />
      </Widget>

    );
  }
}



export default connect()(ContactUs);
