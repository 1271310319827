import React from 'react';
import './style.css';


export function Checkbox({ enabled, onToggle }) {
    return (
        <label className="container">
            <input type="checkbox" checked={enabled}  onChange={e => onToggle(e.target.checked)}/>
            <span className="checkbox"></span>
        </label>
    );
}
