import config from '../../config';
import axios from 'axios';
import { Cookies } from 'react-cookie';



const api = {
	get,
	post,
	put,
	patch,
	delete_,
};

function getToken() {
	const cookies = new Cookies();
	const user = cookies.get('user');
	const token = 'Token ' + user.token;
	return token;
}

function getHeader() {
	const header = {
		headers: {
			Authorization: getToken(),
		},
	};
	return header;
}

function getMultipartHeader() {
	const multipartHeader = {
		headers: {
			Authorization: getToken(),
			'Content-Type': 'multipart/form-data',
		},
	};
	return multipartHeader;
}


function get(endpoint, header = getHeader(), data) {
	const url = `${config.apiUrl}${endpoint}`;

	return axios
		.get(url, header, data)
		.then((response) => {
			return response;
		})
		.catch((error) => {
			return error;
		});
}

function post(endpoint, data, multipartHeader = false, header = getHeader()) {
	const url = `${config.apiUrl}${endpoint}`;

	if (multipartHeader) {
		header = getMultipartHeader();
	}

	return axios
		.post(url, data, header)
		.then((res) => {
			return res;
		})
		.catch((error) => {
			return error;
		});
}

function put(endpoint, data, multipartHeader = false, header = getHeader()) {
	const url = `${config.apiUrl}${endpoint}`;

	if (multipartHeader) {
		header = getMultipartHeader();
	}

	return axios
		.put(url, data, header)
		.then((res) => {
			return res;
		})
		.catch((error) => {
			return error;
		});
}

function patch(endpoint, data, multipartHeader = false, header = getHeader()) {
	const url = `${config.apiUrl}${endpoint}`;

	if (multipartHeader) {
		header = getMultipartHeader();
	}

	return axios
		.patch(url, data, header)
		.then((response) => {
			return response;
		})
		.catch((error) => {
			return error;
		});
}

function delete_(endpoint, data, header = getHeader()) {
	const url = `${config.apiUrl}${endpoint}`;

	return axios
		.delete(url, header)
		.then((response) => {
			return response;
		})
		.catch((error) => {
			return error;
		});
}

export default api;
