import React from 'react';
import authenticateService from '../../../services/authenticate';
import Login from '../../../screens/Login';
import { Route } from 'react-router-dom';

const PrivateRoute = ({ component, ...options }) => {
	const user = authenticateService.isUserLoggedIn();
	const finalComponent = user ? component : Login;

	return <Route {...options} component={finalComponent} />;
};

export default PrivateRoute;