import React from 'react';
import './statCard.css'

export default function StatCard({vendorCount, userCount, orderCount, venderLoading=true, userLoading=true, orderLoading=true}) {
    return (
        <ul className='stat-card'>
            <li>
               <div className='counter loading'>
                   {/* {venderLoading && <div className='loader'/>} */}
                   {!venderLoading && <span className='loader-6'>{vendorCount}</span>}
               </div>
               <span className='title'>Vendors</span>
            </li>

            
            <li>
               <div className='counter'>
                   {/* {!userLoading && <div className='loader'/>} */}
                   {!userLoading && <span className='loader-6'>{userCount}</span>}
               </div>
               <span className='title'>Users</span>
            </li>

            
            <li>
               <div className='counter'>
                   {/* {orderLoading && <div className='loader'/>} */}
                   {!orderLoading && <span className='loader-6'>{orderCount}</span>}
               </div>
               <span className='title'>Orders</span>
            </li>
        </ul>
    );
}
