import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

import authenticateService from '../../../../services/authenticate';

import './style.css';

import ComapnyLogo from '../../../../shared/images/branding/logo/logo192_transparent.png';

const SIDENAV_ITEMS = [
	{
		icon: 'home',
		name: 'home',
		to: '/home',
	},
	{
		icon: 'person',
		name: 'vendor',
		to: '/vendor',
	},
	{
		icon: 'group',
		name: 'user',
		to: '/user',
	},
	{
		icon: 'local_shipping',
		name: 'vehicle',
		to: '/vehicle',
	},
	{
		icon: 'assignment',
		name: 'Policies',
		to: '/policy/faq',
	},
	{
		icon: 'shopping_cart',
		name: 'Orders',
		to: '/order',
	},
	{
		icon: 'shopping_cart',
		name: 'Cancellation Reasons',
		to: '/cancellation',
	},
	{
		icon: 'mail',
		name: 'Cancelled Orders',
		to: '/cancelled-order',
	},
	{
		icon: 'mail',
		name: 'Contact us',
		to: '/contact-us',
	},
	{
		icon: 'backup',
		name: 'Import CSV',
		to: '/importCSVData',
	},
	{
		icon: 'exit_to_app',
		name: 'logout',
		to: '/login',
		onClick: authenticateService.logout,
	},
];

const Sidenav = () => {
	const items = SIDENAV_ITEMS.map((item, idx) => {
		return (
			<NavLink
				key={idx}
				to={item.to}
				onClick={item.onClick || null}
				className="sidenav-link center-both"
			>
				<span className="material-icons">{item.icon}</span>
				<li>{item.name}</li>
			</NavLink>
		);
	});

	return (
		<nav className="sidenav-container left-fade-in fill-mode-forwards">
			<div className="sidenav-seperator" />

			<ul className="sidenav">
				{items}
			</ul>

			<div className="sidenav-seperator" />
		</nav>
	);
};

const Logo = () => {
	return (
		<div className="sidebar-logo-container center-both">
			<div className="logo sidebar-logo">
				<img src={ComapnyLogo} alt="jurnix-logo" className="center-both" />
			</div>
		</div>
	);
};

class Sidebar extends Component {
	handleLogout() { }

	render() {
		return (
			<div className="sidebar ">
				<Logo />
				<Sidenav />
			</div>
		);
	}
}

export default Sidebar;
