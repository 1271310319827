import api from './';

const userAPI = {
	fetchUser,
};

function fetchUser(id="") {
	const endpoint = `getuser/${id}`;

	return api
		.get(endpoint)
		.then((response) => {
			return response;
		})
		.catch((error) => {
			return error;
		});
}

export default userAPI;
