import vendorAPI from '../../api/vendorAPI';

export const FETCH_VENDOR_LIST = 'FETCH_VENDOR_LIST';
export const FETCH_VENDOR_LIST_BEGIN = 'FETCH_VENDOR_LIST_BEGIN';
export const FETCH_VENDOR_LIST_SUCCESS = 'FETCH_VENDOR_LIST_SUCCESS';
export const FETCH_VENDOR_LIST_FAILURE = 'FETCH_VENDOR_LIST_FAILURE';
export const UPDATE_VENDOR_LIST = 'UPDATE_VENDOR_LIST';

export function fetchVendorList() {
	return (dispatch) => {
		dispatch(fetchVendorListBegin());
		return vendorAPI
			.fetch()
			.then((response) => {
                if (response.data.errors) {
                    dispatch(fetchVendorListFailure("api call exception"));
                } else {
                    dispatch(updateVendorList(response.data.results));
                    dispatch(fetchVendorListSuccess());
                }
				return response;
			})
			.catch((error) => {
				dispatch(fetchVendorListFailure("api call error"));
				return error;
			});
	};
}

export function fetchVendorListBegin() {
	return { type: FETCH_VENDOR_LIST_BEGIN };
}

export function fetchVendorListSuccess() {
	return { type: FETCH_VENDOR_LIST_SUCCESS };
}

export function fetchVendorListFailure(error) {
	return { type: FETCH_VENDOR_LIST_FAILURE, data: error };
}

export function updateVendorList(newList) {
	return { type: UPDATE_VENDOR_LIST, data: newList };
}
