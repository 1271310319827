import React, { Component } from 'react';
//import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import Widget from '../../shared/Widget';
import TablePaginated from "../../shared/TablePaginated/Table";
import './style.css';
import api from '../../../../services/api';
import { ROW_COMPONENT, ROW_COMPONENT_LOADING, HEADER_COMPONENT } from './CancelOrderComponent';
//import orderAPI from '../../../../services/api/orderAPI';

const COLUMN = [
  {
    name: "sender",
    dataKey: "sender_name",
    size: 2,
  },
  {
    name: "reciever",
    dataKey: "receiver_name",
    size: 1,
  },
  {
    name: "gender",
    dataKey: "gender",
    size: 1,
  },
];
class CancelledOrder extends Component {
  state = {
    list: [],
    itemsPerPageCount: 0,
    currentPage: 0,
    pageCount: 0,
    listFetchingInProgress: false,
  }

  setCurrentPage = (currentPage) => {
    this.setState({ currentPage, listFetchingInProgress: true });
    api.get(`/order/cancel_order/?page=${currentPage}`)
      .then((res) => {
        const list = res.data.results.map(i => i.order_id);
        this.setState({ list, listFetchingInProgress: false });
      })
      .catch((err) => {
        this.setState({ listFetchingInProgress: false });
      });
  };
  render() {
    const {
      currentPage,
      pageCount,
      list,
      listFetchingInProgress,
    } = this.state;

    return (
      <Widget className="screen-not-widget" title="Cancelled Orders" size="wide" screenNotWidget={true}>
        <TablePaginated
          columns={COLUMN}
          data={list}
          dataLoading={listFetchingInProgress}
          rowComponent={ROW_COMPONENT}
          rowComponentLoading={ROW_COMPONENT_LOADING}
          headerComponent={HEADER_COMPONENT}
          currentPage={currentPage}
          pageCount={pageCount}
          setCurrentPage={this.setCurrentPage}
        //searchQuery={searchQuery}
        //setSearchQuery={this.setSearchQuery}
        //rowLink="/order/"
        //rowLinkAccessor="id"
        />
      </Widget>

    );
  }
  componentDidMount = () => {
    this.setState({ listFetchingInProgress: true });
    api.get('/order/cancel_order/').then(res => {
      console.log('res', res.data.results)
      const list = res.data.results.map(i => i.order_id);
      //console.log('list', list.map(i => i.order_id))
      const itemsPerPageCount = list.length;
      const currentPage = list ? 1 : 0;
      const pageCount = itemsPerPageCount
        ? Math.ceil(res.data.count / itemsPerPageCount)
        : 0;
      this.setState({
        list,
        itemsPerPageCount,
        currentPage,
        pageCount,
        listFetchingInProgress: false,
      });
    })
      .catch((err) => {
        this.setState({ listFetchingInProgress: false });
      });
  }

}



export default connect(null, null)(CancelledOrder);
