import React, { Component } from 'react';
import Widget from '../../shared/Widget';
import ImageViewer from '../../shared/ImageViewer';
import Loader from '../../../../shared/components/Loader';
import vendorAPI from '../../../../services/api/vendorAPI';
import { Checkbox } from '../../../../shared/components/input';
import './style.css';


const DetailBlock = ({ heading, items }) => {
	return (
		<div className="block">
			<div className="detail-block">
				<div className="detail-heading">{heading}</div>
				{items.map((item, idx) => {
					if (item.image) {
						return (
							<div key={idx} className={`detail-item center-both hover-rise`}>
								<ImageViewer src={item.src} alt={item.alt} width={96} height={72} />
							</div>
						);
					}
					else {
						return (
							<div key={idx} className="detail-item hover-rise">
								<span className="detail-label">{item.label}</span>
								<span className="detail-text">{item.text}</span>
							</div>
						);
					}
				})}
			</div>
		</div>
	);
};

class VendorDetail extends Component {
	state = {
		detail: {
			transport_detail: {},
		},
		id: this.props.match.params.id,
		loading: true,
		verifying: false,
		verficationSuccess: false,
		verficationFail: false,

		verifyNationalID: false,
		verifyVehicleDocument: false,
		verifyDrivingLicense: false,
		verifyNationalIDComment: "",
		verifyVehicleDocumentComment: "",
		verifyDrivingLicensComment: "",
	};

	componentDidMount() {
		this.fetchVendorDetail();
	}

	fetchVendorDetail() {
		vendorAPI.fetchDetail(this.state.id).then((res) => {
			this.setState({
				detail: res.data || {},
				loading: false,

				verifyNationalID: res.data.national_id_verified,

				verifyVehicleDocument: res.data.transport_detail ?
					res.data.transport_detail.vichel_document_verified
					:
					false,

				verifyDrivingLicense: res.data.transport_detail ?
					res.data.transport_detail.driving_licance_document_verified
					:
					false,
				verifyNationalIDComment: res.data.national_id_admin_comm || "",

				verifyVehicleDocumentComment: res.data.transport_detail ?
					res.data.transport_detail.vichel_document_admin_comm
					:
					"",
				verifyDrivingLicenseComment: res.data.transport_detail ?
					res.data.transport_detail.driving_licance_admin_comm
					:
					"",
			});


		});
	}

	verifyVendor = () => {
		if (!this.state.verifying) {
			this.setState({
				verifying: true,
				verficationSuccess: false,
				verficationFail: false,
			})

			const verficationOptions = {
				verifyNationalID: this.state.verifyNationalID,
				verifyVehicleDocument: this.state.verifyVehicleDocument,
				verifyDrivingLicense: this.state.verifyDrivingLicense,
				verifyNationalIDComment: this.state.verifyNationalIDComment,
				verifyVehicleDocumentComment: this.state.verifyVehicleDocumentComment,
				verifyDrivingLicenseComment: this.state.verifyDrivingLicenseComment,
			}
			/*---------------------------------|CHANGES NEEDED|------------------------------ */
			// this.state.detail.transport_detail.id
			// console.log(verficationOptions);
			vendorAPI.verify(this.state.detail.transport_detail.id, this.state.detail, verficationOptions).then(response => {
				this.setState({ verifying: false })
				if (response.data.errors) {
					this.setState({ verficationFail: true })
				} else {
					this.setState({ verficationSuccess: true });
					this.fetchVendorDetail();
				}
			}).catch(response => {
				this.setState({ verifying: false, verficationFail: true })
			})
		}
	}

	render() {
		const {
			profile_pic,
			fullname,
			gender,
			dob,
			permanent_address,
			phone_no_registered,
			// vendor_verified,
			is_reviewed,
			national_id,
			national_id_admin_comm,
			national_id_number,
			national_id_verified,
			bank_detail,
			transport_detail,
		} = this.state.detail;

		const {
			verifying,
			verficationSuccess,
			verficationFail,
		} = this.state;
		return (
			<Widget
				className="screen-not-widget"
				title="Vendor Detail"
				size="wide"
				screenNotWidget={true}
				loading={this.state.loading}
			>
				<div className="block-container scroll">
					<DetailBlock
						heading={`profile (${is_reviewed ? 'Reviewed' : 'Not Reviewed Yet'})`}
						items={[
							{ image: true, src: profile_pic, alt: 'vendor profile' },
							{ label: 'name', text: fullname },
							{ label: 'Gender', text: gender },
							{ label: 'dob', text: dob },
							{ label: 'Address', text: permanent_address },
							{ label: 'Phone', text: phone_no_registered },
						]}
					/>

					<DetailBlock
						heading={`National ID (${national_id_verified ? 'Verified' : 'Not Verified'})`}
						items={[
							{ image: true, src: national_id, alt: 'national id' },
							{ label: 'ID', text: national_id_number },
							{ label: 'ID Verified', text: national_id_verified ? 'Yes' : 'No' },
							{ label: 'Comment', text: national_id_admin_comm || 'No Comment Found' },
						]}
					/>

					{bank_detail && (
						<DetailBlock
							heading={`Bank Detail`}
							items={[
								{ label: 'Account Holder', text: bank_detail.account_holder_name },
								{ label: 'Account Number', text: bank_detail.account_no },
								{ label: 'Bank', text: bank_detail.bank_name },
								{ label: 'Branch Code', text: bank_detail.branch_code },
							]}
						/>
					)}

					{transport_detail && (
						<DetailBlock
							heading={`Driving License (${transport_detail.driving_licance_document_verified ? 'Verified' : 'Not Verified'})`}
							items={[
								{
									image: true,
									src: transport_detail.driving_licance_document,
									alt: 'driving license document',
								},
								{ label: 'license Number', text: transport_detail.driving_licance_no },
								{ label: 'owner name', text: transport_detail.ownername },
								{ label: 'owner phone', text: transport_detail.ownerphone },
							]}
						/>
					)}

					{transport_detail && (
						<DetailBlock
							heading={`Vehicle Info (${transport_detail.vichel_document_verified ? 'Verified' : 'Not Verified'})`}
							items={[
								{
									image: true,
									src: transport_detail.vichel_document,
									alt: 'driving license document',
								},
								{ label: 'Registration Number', text: transport_detail.vichel_registration_no },
								{ label: 'Vehicle Type', text: transport_detail.vichel_type },
							]}
						/>
					)}


					<div className="block">
						<div className="detail-block">
							<div className="detail-heading">Verification</div>

							<div className="detail-item hover-rise">
								<span className="detail-label">national id</span>
								<span className={`detail-text ${national_id_verified ? 'success-text' : 'fail-text'}`}>{`${national_id_verified ? 'Verified' : 'Not Verified'}`}</span>
							</div>

							{transport_detail && <div className="detail-item hover-rise">
								<span className="detail-label">Driving License</span>
								<span className={`detail-text ${transport_detail.driving_licance_document_verified ? 'success-text' : 'fail-text'}`}>{`${transport_detail.driving_licance_document_verified ? 'Verified' : 'Not Verified'}`}</span>
							</div>}
							{!transport_detail && <div className="detail-item hover-rise">
								<span className="detail-label">Driving License</span>
								<span className={'fail-text'}>Not Available</span>
							</div>}


							{transport_detail && <div className="detail-item hover-rise">
								<span className="detail-label">Vehicle Document</span>
								<span className={`detail-text ${transport_detail.vichel_document_verified ? 'success-text' : 'fail-text'}`}>{`${transport_detail.vichel_document_verified ? 'Verified' : 'Not Verified'}`}</span>
							</div>}
							{!transport_detail && <div className="detail-item hover-rise">
								<span className="detail-label">Vehicle Document</span>
								<span className={'fail-text'}>Not Available</span>

							</div>
							}



							{transport_detail && <div>
								<br />
								<hr />
								<br />
							</div>
							}

							{transport_detail && <div className="detail-item hover-rise">

								<span className="detail-label">national id</span>
								<span>
									<Checkbox enabled={this.state.verifyNationalID} onToggle={v => { this.setState({ verifyNationalID: v }) }} />
								</span>
							</div>}
							{transport_detail &&

								<form className="policy-form vehicle-form" onSubmit={e => e.preventDefault()}>

									<label className="hover-rise w-100" htmlFor="verifyNationalIDComment">
										write a comment(character limit: 100)
						</label>
									<textarea
										className="hover-rise scroll"
										type="text"
										id="verifyNationalIDComment"
										value={this.state.verifyNationalIDComment}
										onChange={e => this.setState({ verifyNationalIDComment: e.target.value })}
										maxLength="100"
										required
									/>
								</form>
							}


							{transport_detail && <div>
								<br />
								<hr />
								<br />
							</div>
							}


							{transport_detail && <div className="detail-item hover-rise">
								<span className="detail-label">driving license</span>
								<span>
									<Checkbox enabled={this.state.verifyDrivingLicense} onToggle={v => { this.setState({ verifyDrivingLicense: v }) }} />
								</span>
							</div>}


							{transport_detail &&
								<form className="policy-form vehicle-form" onSubmit={e => e.preventDefault()}>

									<label className="hover-rise w-100" htmlFor="verifyDrivingLicenseComment">
										write a comment(character limit: 100)
						</label>
									<textarea
										className="hover-rise scroll"
										type="text"
										id="verifyDrivingLicenseComment"
										value={this.state.verifyDrivingLicenseComment}
										onChange={e => this.setState({ verifyDrivingLicenseComment: e.target.value })}
										maxLength="100"
										required
									/>
								</form>}



							{transport_detail && <div>
								<br />
								<hr />
								<br />
							</div>
							}


							{transport_detail && <div className="detail-item hover-rise">
								<span className="detail-label">vehicle document</span>
								<span>
									<Checkbox enabled={this.state.verifyVehicleDocument} onToggle={v => { this.setState({ verifyVehicleDocument: v }) }} />
								</span>
							</div>
							}

							{transport_detail &&
								<form className="policy-form vehicle-form" onSubmit={e => e.preventDefault()}>

									<label className="hover-rise w-100" htmlFor="verifyVehicleDocumentComment">
										write a comment(character limit: 100)
						</label>
									<textarea
										className="hover-rise scroll"
										type="text"
										id="verifyVehicleDocumentComment"
										value={this.state.verifyVehicleDocumentComment}
										onChange={e => this.setState({ verifyVehicleDocumentComment: e.target.value })}
										maxLength="100"
										required
									/>
								</form>

							}

							{transport_detail && <div>
								<br />
							</div>
							}

							{transport_detail &&
								<div>

									{!verifying && verficationSuccess && (
										<div className="center-both p-1">
											<p className="center-both success-text fall-down-fade-in hover-rise fill-mode-forwards text-center ">
												Vendor Verified{' '}
												<span className="material-icons center-both">check</span>
											</p>
										</div>
									)}

									{!verifying && verficationFail && (
										<div className="center-both p-1">
											<p className="center-both fail-text fall-down-fade-in hover-rise fill-mode-forwards text-center ">
												Vendor Not Verified, Something went wrong
										</p>
										</div>
									)}



									{
										// (
										// 	!national_id_verified ||
										// 	(transport_detail && !transport_detail.driving_licance_document_verified) ||
										// 	(transport_detail && !transport_detail.vichel_document_verified)
										// )
										// 	&& (
										// 		this.state.verifyNationalID ||
										// 		this.state.verifyVehicleDocument ||
										// 		this.state.verifyDrivingLicense
										// 	)
										// 	&& !verifying
										true
										&& (<div className="center-both all-vehicle-button hover-rise  rise-up-fade-in fill-mode-forwards"
											onClick={() => {
												this.setState({
													verifying: true,
												});
												this.verifyVendor();
											}}
											ref={input => input && input.focus()}
										>
											verify
										</div>)
									}


									{verifying && (<div>
										<Loader />
									</div>)}



									<br />
									<br />
								</div>
							}

						</div>
					</div>
				</div>
			</Widget>
		);
	}
}

export default VendorDetail;
