import React, { Component } from 'react';
import { connect } from 'react-redux';

import Loader from '../../../../../shared/components/Loader';
import privacyPolicyAPI from '../../../../../services/api/privacyPolicyAPI';
import { fetchPrivacyPolicyList } from '../../../../../services/redux/action/privacyPolicyAction';

import Table from '../../../shared/Table';

import './style.css';
import './radioButton.css';

const SETTINGS_PRIVACY_POLICY_TABLE = {
	columns: [
		{
			name: 'title',
			data_name: 'title',
		},
		{
			name: 'content',
			data_name: 'content',
		},
	],
};

class PrivacyPolicyAdd extends Component {
	state = {
		title: '',
		content: '',
		privacyPolicyAddSuccess: false,
		privacyPolicyAddFail: false,
		sending: false,
	};

	resetForm = () => {
		this.setState({
			title: '',
			content: '',
			privacyPolicyAddSuccess: false,
			privacyPolicyAddFail: false,
			sending: false,
		});
	};

	addFAQ = () => {
		const data = {
			title: this.state.title,
			content: this.state.content,
		};

		privacyPolicyAPI
			.create(data)
			.then((response) => {
				if (response.errors) {
					this.setState({
						loading: false,
						privacyPolicyAddFail: true,
					});
				}
				else {
					this.setState({
						loading: false,
						privacyPolicyAddSuccess: true,
					});
					this.props.dispatch(fetchPrivacyPolicyList());
				}
			})
			.catch(() => {
				this.setState({
					loading: false,
					privacyPolicyAddFail: true,
				});
			});
	};

	handleSubmit = (e) => {
		e.preventDefault();
		this.setState({
			sending: true,
			privacyPolicyAddFail: false,
		});
		this.addFAQ();
	};

	handleChange = (e) => {
		console.log(e.target.value);
		// e.target.checked = 'checked';
		this.setState({
			[e.target.id]: e.target.value,
		});
	};

	handleAddAnother = (e) => {
		e.preventDefault();
		this.resetForm();
	};

	render() {
		const { title, content, privacyPolicyAddFail, privacyPolicyAddSuccess, sending } = this.state;
		return (
			<div className="form w-100 h-100 rise-up-fade-in fill-mode-forwards scroll">
				{!privacyPolicyAddSuccess && (
					<form className="policy-form vehicle-form" onSubmit={this.handleSubmit} autocomplete="off">
						<label className="hover-rise" htmlFor="title">
							Title
						</label>
						<input
							className="hover-rise scroll"
							type="text"
							id="title"
							value={title}
							onChange={this.handleChange}
							required
						/>

						<label className="hover-rise" htmlFor="content">
							Content
						</label>
						<textarea
							className="hover-rise scroll"
							type="text"
							id="content"
							value={content}
							onChange={this.handleChange}
							required
						/>

						{/* <label> */}
						{sending && <Loader />}
						{privacyPolicyAddFail && (
							<div className="vehicle-button-area center-both">
								<p className="center-both fail-text hover-rise fall-down-fade-in fill-mode-forwards text-center ">
									Privacy Policy Not Added <span class="material-icons center-both">check</span>
								</p>
							</div>
						)}
						<div className="center-both">
							{/* </label> */}

							<input
								type="submit"
								className="center-both login-button add-more-button hover-rise"
								id="submit"
								value={sending ? 'Adding' : privacyPolicyAddFail ? 'Retry' : 'Add'}
								required
							/>
						</div>
					</form>
				)}

				{privacyPolicyAddSuccess && (
					<div className="center-both h-100">
						{/* </label> */}
						<div>
							<div className="vehicle-button-area center-both">
								<p className="center-both success-text hover-rise fall-down-fade-in fill-mode-forwards text-center ">
									Privacy Policy Added Successfully{' '}
									<span class="material-icons center-both">check</span>
								</p>
							</div>
							<div className="vehicle-button-area">
								<div
									className="center-both add-more-button hover-rise  rise-up-fade-in fill-mode-forwards"
									onClick={this.handleAddAnother}
								>
									Add Another
								</div>
							</div>
						</div>
					</div>
				)}
			</div>
		);
	}
}

const PrivacyPolicy = ({ privacyPolicyList, privacyPolicyListFetchingInProgress, dispatch }) => {
	return (
		<div className="policy-tab-content block-container">
			<div className="block">
				<div className="detail-heading">Add Privacy Policy</div>
				<PrivacyPolicyAdd dispatch={dispatch} />
			</div>
			<div className="block">
				<div className="detail-heading">Privacy Policies</div>
				<Table
					settings={SETTINGS_PRIVACY_POLICY_TABLE}
					data={privacyPolicyList}
					loading={privacyPolicyListFetchingInProgress}
					statusKey="id"
				/>
			</div>
		</div>
	);
};

function mapStateToProps(state) {
	return {
		privacyPolicyList: state.privacyPolicy.list,
		privacyPolicyListFetchingInProgress: state.privacyPolicy.listFetchingInProgress,
	};
}

export default connect(mapStateToProps)(PrivacyPolicy);
