import termsConditionAPI from '../../api/termsConditionAPI';

export const FETCH_TERMS_CONDITION_LIST = 'FETCH_TERMS_CONDITION_LIST';
export const FETCH_TERMS_CONDITION_LIST_BEGIN = 'FETCH_TERMS_CONDITION_LIST_BEGIN';
export const FETCH_TERMS_CONDITION_LIST_SUCCESS = 'FETCH_TERMS_CONDITION_LIST_SUCCESS';
export const FETCH_TERMS_CONDITION_LIST_FAILURE = 'FETCH_TERMS_CONDITION_LIST_FAILURE';
export const UPDATE_TERMS_CONDITION_LIST = 'UPDATE_TERMS_CONDITION_LIST';

export function fetchTermsConditionList() {
	return (dispatch) => {
		dispatch(fetchTermsConditionListBegin());
		return termsConditionAPI
			.fetch()
			.then((response) => {
                if (response.data.errors) {
                    dispatch(fetchTermsConditionListFailure("privacy policy api call exception"));
                } else {
                    dispatch(updateTermsConditionList(response.data.data));
                    dispatch(fetchTermsConditionListSuccess());
                }
				return response;
			})
			.catch((error) => {
				dispatch(fetchTermsConditionListFailure("privacy policy api call error"));
				return error;
			});
	};
}

export function fetchTermsConditionListBegin() {
	return { type: FETCH_TERMS_CONDITION_LIST_BEGIN };
}

export function fetchTermsConditionListSuccess() {
	return { type: FETCH_TERMS_CONDITION_LIST_SUCCESS };
}

export function fetchTermsConditionListFailure(error) {
	return { type: FETCH_TERMS_CONDITION_LIST_FAILURE, data: error };
}

export function updateTermsConditionList(newList) {
	return { type: UPDATE_TERMS_CONDITION_LIST, data: newList };
}
