import React, { Component } from 'react';
import config from '../../config';
// import { NavLink } from 'react-router-dom';

// import { InputUsername, InputPassword, LoginButton } from './components/LoginFormElements';
import authenticateService from '../../services/authenticate';
import Loader from '../../shared/components/Loader';

import ComapnyLogo from '../../shared/images/branding/logo/logo192_transparent.png';

import './style.css';
import './loginInputs.css';

const Logo = () => {
	return (
		<div className="logo">
			<img src={ComapnyLogo} className="center-both" alt="jurnix-logo" />
		</div>
	);
};

const WelcomeMessage = () => {
	return (
		<div className="welcome-message">
			<h2 className="welcome-heading">Welcome Back</h2>
			<p className="welcome-subheading">Sign in to Jurnix</p>
		</div>
	);
};

const InputUsername = () => {
	return (
		<input
			autoComplete="off"
			id="username"
			type="text"
			placeholder="Username"
			className="login-input"
		/>
	);
};

const InputPassword = () => {
	return (
		<input
			autoComplete="new-password"
			id="password"
			onFocus={(e) => {
				e.target.removeAttribute('readonly');
			}}
			type="password"
			placeholder="Password"
			className="login-input"
		/>
	);
};

const LoginButton = ({ text }) => {
	return <input type="submit" value={text} className="login-button center-both" />;
};

const AppVersion = () => {
	return (
		<div className="uncopyable center-both version-text login-app-version">{config.appVersion}</div>
	);
}


class Login extends Component {
	state = {
		loading: false,
	};

	handleLogin = (e, v) => {
		e.preventDefault();
		if (this.state.loading) return;
		this.setState({
			loading: true,
		});

		const username = e.target.elements.username.value;
		const password = e.target.elements.password.value;
		console.log('from values:\n', username, password);
		if (username === 'admin' && password === 'adminADMIN') {
			authenticateService.login('9876543210', '9876543210').then((res) => {
				console.log('ADMIN login success');
				this.props.history.push('/');
				this.setState({
					loading: false,
				});
			});
		}
		else {
			authenticateService.login(username, password).then((res) => {
				console.log('login success');
				this.props.history.push('/');
				this.setState({
					loading: false,
				});
			});
		}
	};

	render() {
		return (
			<React.Fragment>

				<div className="login-form rise-up-fade-in fill-mode-forwards">
					<Logo />
					<WelcomeMessage />

					<hr />

					<div className="form">
						<form onSubmit={this.handleLogin} autoComplete="off">
							{/* <Loader/> */}
							<InputUsername />
							<InputPassword />

							{this.state.loading && <Loader />}

							<LoginButton
								className="button-push-bump"
								text={this.state.loading ? 'Authenticating' : 'Login'}
							/>
						</form>
					</div>

				</div>
				<AppVersion />
			</React.Fragment>
		);
	}
}

export default Login;
