import React, { Component } from 'react';

import Widget from '../../shared/Widget';
import ImageViewer from '../../shared/ImageViewer';

import VehicleAPI from '../../../../services/api/vehicleAPI';

import './style.scss';
import { Link } from 'react-router-dom';

const DetailBlock = ({ heading, items }) => {
	return (
		<div className="block">
			<div className="detail-block">
				<div className="detail-heading">{heading}</div>
				{items.map((item, idx) => {
					if (item.image) {
						return (
							<div key={idx} className={`detail-item center-both hover-rise`}>
								<ImageViewer src={item.src} alt={item.alt} width={96} height={72} />
							</div>
						);
					}
					else {
						return (
							<div key={idx} className="detail-item hover-rise">
								<span className="detail-label">{item.label}</span>
								<span className="detail-text">{item.text}</span>
							</div>
						);
					}
				})}
			</div>
		</div>
	);
};

class VehicleDetail extends Component {
	state = {
		detail: {},
		id: this.props.match.params.id,
		loading: true,
	};

	componentDidMount() {
		this.fetchVehicleDetail();
	}

	fetchVehicleDetail() {
		VehicleAPI.fetchVehicle(this.state.id).then((res) => {
			this.setState({
				detail: res.data,
				loading: false,
			});
			console.log('Vehicle detail :\n', this.state.detail);
		});
	}

	render() {
		const { id, vichel_type, max_weight_capacity, max_volume, base_fare, cost_per_kg, icon, max_dist_in_2_to_7_day,
			max_dist_in_24_hour,
			max_dist_in_hrs24to2Days,
			max_dist_in_more_then_17_days,
			max_dist_in_one_hour,
			max_dist_in_same_day,
			max_dist_in_seven_day_to_14,
			max_dist_in_three_hour,
			max_volume_in_2_to_7_day,
			max_volume_in_24_hour,
			max_volume_in_hrs24to2Days,
			max_volume_in_more_then_17_days,
			max_volume_in_one_hour,
			max_volume_in_same_day,
			max_volume_in_seven_day_to_14,
			max_volume_in_three_hour,
			max_weight_in_2_to_7_day,
			max_weight_in_24_hour,
			max_weight_in_hrs24to2Days,
			max_weight_in_more_then_17_days,
			max_weight_in_one_hour,
			max_weight_in_same_day,
			max_weight_in_seven_day_to_14,
			max_weight_in_three_hour, } = this.state.detail;
		return (
			<Widget
				className="screen-not-widget"
				title="Vehicle Detail"
				size="wide"
				screenNotWidget={true}
				loading={this.state.loading}
			>
				<div className="detail-screen scroll " >
					<div >
						<div className="block-container">
							<DetailBlock
								heading="general"
								items={[
									{ image: true, src: icon, alt: 'vehicle icon' },
									{ label: 'Vehicle Type', text: vichel_type },
									{ label: 'Max Weight', text: max_weight_capacity },
									{ label: 'Max Volume', text: max_volume },
									{ label: 'Base Fare', text: base_fare },
									{ label: 'Cost per kg', text: cost_per_kg },
								]}
							/>

							<DetailBlock
								heading="Max Distance"
								items={[
									{ label: 'same day', text: max_dist_in_same_day },
									{ label: '1 hour', text: max_dist_in_one_hour },
									{ label: '3 hours', text: max_dist_in_three_hour },
									{ label: '24 hours', text: max_dist_in_24_hour },
									{ label: '1-2 days', text: max_dist_in_hrs24to2Days },
									{ label: '2-7 days', text: max_dist_in_2_to_7_day },
									{ label: '7-14 days', text: max_dist_in_seven_day_to_14 },
									{ label: '< 17 days', text: max_dist_in_more_then_17_days },
								]}
							/>

							<DetailBlock
								heading="Max Volume"
								items={[
									{ label: 'max volume', text: max_volume },
									{ label: 'same day', text: max_volume_in_same_day },
									{ label: '1 hour', text: max_volume_in_one_hour },
									{ label: '3 hours', text: max_volume_in_three_hour },
									{ label: '24 hours', text: max_volume_in_24_hour },
									{ label: '1-2 days', text: max_volume_in_hrs24to2Days },
									{ label: '2-7 days', text: max_volume_in_2_to_7_day },
									{ label: '7-14 days', text: max_volume_in_seven_day_to_14 },
									{ label: '< 17 days', text: max_volume_in_more_then_17_days },
								]}
							/>


							<DetailBlock
								heading="Max Weight"
								items={[
									{ label: 'max weight', text: max_weight_capacity },
									{ label: 'same day', text: max_weight_in_same_day },
									{ label: '1 hour', text: max_weight_in_one_hour },
									{ label: '3 hours', text: max_weight_in_three_hour },
									{ label: '24 hours', text: max_weight_in_24_hour },
									{ label: '1-2 days', text: max_weight_in_hrs24to2Days },
									{ label: '2-7 days', text: max_weight_in_2_to_7_day },
									{ label: '7-14 days', text: max_weight_in_seven_day_to_14 },
									{ label: '< 17 days', text: max_weight_in_more_then_17_days },
								]}
							/>

						</div>

					</div>
					<div className="center-both">
						<Link to={`/vehicle/${id}/edit`}>
							<input
								type="submit"
								className="center-both edit-button hover-rise"
								id="submit"
								value='Edit'
								required
							/>
						</Link>
					</div>
				</div>
			</Widget>
		);
	}
}

export default VehicleDetail;
