import {
	FETCH_VEHICLE_LIST,
	FETCH_VEHICLE_LIST_BEGIN,
	FETCH_VEHICLE_LIST_SUCCESS,
	FETCH_VEHICLE_LIST_FAILURE,
	UPDATE_VEHICLE_LIST,
} from '../action/vehicleAction';

// const vehicleList = [
// 	{
// 		id: 1,
// 		vichel_type: 'byk',
// 		max_weight_capacity: 20,
// 		max_volume: '200',
// 		base_fare: 20,
// 		per_km_charges: 20,
// 		cost_per_kg: 20,
// 		cost_per_min: 20,
// 		per_volume_charge: 20,
// 		icon:
// 			'https://storage.googleapis.com/jurnix-user-profile-dev/icon_None/icon/icon.png?Expires=1589581078&GoogleAccessId=storage-admin-dev-32%40proud-maker-274213.iam.gserviceaccount.com&Signature=AOHBpQ7LGiEdXu0cTVlXiJbna5SPTM7wJG4LCLNUhNxtnPvyvd0lnraTatUtCPdE%2Btap0rjZbgOoiKjgRYfEXGMZ3zhoz99xCZ07PDhkq1PItSZnIA4vDNHG1W6HXedHEvVoOMlOUyFKBFfurl1SERt413ZKfmMj%2FDQdDL6YceRrJiwm48T6h3hmsVL20cbwg9c6%2BRQvyM86FxMk%2BrbuqBUPoWOcrZfQSW9hn8Be9mwFZaeSlzBAUfEjDUqA9JakvIT%2FFBquaPF6lsZocXBm%2FjEppt5fVtHW37txsKZem%2FmR%2FbdO%2BvzKSX3m1VJ3jJLJrzLiWQSj8lzL1izHwjuZDw%3D%3D',
// 	},
// ];

const initialState = {
	list: [],
	listError: '',
	listFetchingInProgress: false,
};

function vehicle(state = initialState, action) {
	switch (action.type) {
		case FETCH_VEHICLE_LIST:
			return {
				...state,
			};

		case FETCH_VEHICLE_LIST_BEGIN:
			return {
				...state,
				listFetchingInProgress: true,
			};

		case FETCH_VEHICLE_LIST_SUCCESS:
			return {
				...state,
				listFetchingInProgress: false,
			};

		case FETCH_VEHICLE_LIST_FAILURE:
			return {
				...state,
				listError: action.data,
				listFetchingInProgress: false,
			};

		case UPDATE_VEHICLE_LIST:
			return {
				...state,
				list: action.data,
			};

		default:
			return state;
	}
}

export default vehicle;
