import {
	FETCH_USER_LIST,
	FETCH_USER_LIST_BEGIN,
	FETCH_USER_LIST_SUCCESS,
	FETCH_USER_LIST_FAILURE,
	UPDATE_USER_LIST,
} from '../action/userAction';

// const userList = [
// 	{
// 		id: 1,
// 		last_login: null,
// 		is_superuser: false,
// 		first_name: 'aaa',
// 		last_name: 'bbb',
// 		is_staff: false,
// 		is_active: true,
// 		date_joined: '2020-05-14T13:44:05.623588Z',
// 		email: 'golubajpaihkj302@gmail.com',
// 		user_name: "asdf",
// 		phone: 1234,
// 		country_code: null,
// 		phone_verified: false,
// 		email_verified: false,
// 		is_vendor: false,
// 		alive: true,
// 		groups: [],
// 		user_permissions: [],
// 	}
// ];

const initialState = {
	list: [],
	listError: '',
	listFetchingInProgress: false,
};

function user(state = initialState, action) {
	switch (action.type) {
		case FETCH_USER_LIST:
			return {
				...state,
			};

		case FETCH_USER_LIST_BEGIN:
			return {
				...state,
				listFetchingInProgress: true,
			};

		case FETCH_USER_LIST_SUCCESS:
			return {
				...state,
				listFetchingInProgress: false,
			};

		case FETCH_USER_LIST_FAILURE:
			return {
				...state,
				listError: action.data,
				listFetchingInProgress: false,
			};

		case UPDATE_USER_LIST:
			return {
				...state,
				list: action.data,
			};

		default:
			return state;
	}
}

export default user;
