import React, { Component } from 'react';
import { Switch } from 'react-router-dom';
import { connect } from 'react-redux';

import config from '../../config';

import Sidebar from './components/Sidebar';
import Header from './components/Header';
import Footer from './components/Footer';
import PrivateRoute from '../../shared/components/PrivateRoute';

import Home from './screens/Home';
import Vendor from './screens/Vendor';
import User from './screens/User';
import Vehicle from './screens/Vehicle';
import HomeRedirect from './screens/HomeRedirect';
import VendorDetail from './screens/VendorDetail';
import UserDetail from './screens/UserDetail';
import VehicleDetail from './screens/VehicleDetail';
import VehicleAdd from './screens/VehicleAdd';
import Policy from './screens/Policy';
import Order from './screens/Order';
import OrderDetail from './screens/OrderDetail';
import ImportCSVData from './screens/ImportCSVData';

import { fetchVendorList } from '../../services/redux/action/vendorAction';
import { fetchVehicleList } from '../../services/redux/action/vehicleAction';
import { fetchUserList } from '../../services/redux/action/userAction';
import { fetchFAQList } from '../../services/redux/action/faqAction';
import { fetchEndUserAgreementList } from '../../services/redux/action/endUserAgreementAction';
import { fetchPrivacyPolicyList } from '../../services/redux/action/privacyPolicyAction';
import { fetchTermsConditionList } from '../../services/redux/action/termsConditionAction';
import { fetchOrderList } from '../../services/redux/action/orderAction';

// import endUserAgreementAPI from '../../services/api/endUserAgreementAPI';
// import privacyPolicyAPI from '../../services/api/privacyPolicyAPI';
// import faqAPI from '../../services/api/faqAPI';
// import aboutUsAPI from '../../services/api/aboutUsAPI';
// import contactUsAPI from '../../services/api/contactUsAPI';
// import termsConditionAPI from '../../services/api/termsConditionAPI';
// import vendorAPI from '../../services/api/vendorAPI';

import './style.css';
import VehicleEdit from './screens/VehicleEdit';
import CancelledOrder from './screens/CancelledOrder';
import ContactUs from './screens/ContactUs';
import CancellationReason from './screens/CancellationReason';
import ReasonAdd from './screens/ReasonAdd';


class Dashboard extends Component {
	componentDidMount() {
		this.updateData(config.dataUpdateFrequency);
		// vendorAPI.verify();
	}

	updateData = (frequency) => {
		if (frequency === 0) {
			return;
		} else if (frequency === -1) {
			this.fetchData();
			return;
		} else if (frequency > 0) {
			setInterval(this.fetchData, frequency);
			return;
		}
	};

	fetchData = () => {
		this.props.dispatch(fetchVendorList());
		this.props.dispatch(fetchVehicleList());
		this.props.dispatch(fetchUserList());
		this.props.dispatch(fetchFAQList());
		this.props.dispatch(fetchEndUserAgreementList());
		this.props.dispatch(fetchPrivacyPolicyList());
		this.props.dispatch(fetchTermsConditionList());
		this.props.dispatch(fetchOrderList());
	}

	addDummyData = () => {
		for (let i = 1; i < 6; ++i) {
			// endUserAgreementAPI.create({content: `#${i} dummy end user agreement`}).then((response) => console.log(response));
			// privacyPolicyAPI.create({content: `#${i} test Privacy Policy`}).then((response) => console.log(response));
			// faqAPI.create({question: `#${i} dummy question`, answer: `#${i} dummy answer`}).then((response) => console.log(response));
			// aboutUsAPI.create({content: `#${i} dummy about us`}).then((response) => console.log(response));
			// contactUsAPI.create({"first_name":"dummy","last_name":"kumar","country_code":"91","content":`#${i} dummy contact query`}).then((response) => console.log(response));
		}

		setTimeout(() => {
			// endUserAgreementAPI.fetch().then((response) => console.log(response));
			// privacyPolicyAPI.fetch().then((response) => console.log(response));
			// faqAPI.fetch(1).then((response) => console.log(response));
			// aboutUsAPI.fetch().then((response) => console.log(response));
			// contactUsAPI.fetch().then((response) => console.log(response));
		}, 1);
	}

	render() {
		// const { match } = this.props;
		return (
			<div className="dashboard">
				<Sidebar />

				<div className="main-area">
					<Header />

					<div className="content-area">
						<Switch>
							<PrivateRoute exact path="/home" component={Home} />

							<PrivateRoute exact path="/vendor" component={Vendor} />
							<PrivateRoute exact path="/vendor/:id" component={VendorDetail} />

							<PrivateRoute exact path="/user" component={User} />
							<PrivateRoute exact path="/user/:id" component={UserDetail} /
							>
							<PrivateRoute exact path="/policy" component={Policy} />
							<PrivateRoute exact path="/policy/:page" component={Policy} />

							<PrivateRoute exact path="/vehicle" component={Vehicle} />
							<PrivateRoute exact path="/vehicle/new" component={VehicleAdd} />
							<PrivateRoute exact path="/vehicle/:id" component={VehicleDetail} />
							<PrivateRoute exact path="/vehicle/:id/edit" component={VehicleEdit} />

							<PrivateRoute exact path="/order" component={Order} />
							<PrivateRoute exact path="/order/:id" component={OrderDetail} />
							<PrivateRoute exact path="/cancelled-order" component={CancelledOrder} />

							<PrivateRoute exact path="/contact-us" component={ContactUs} />

							<PrivateRoute exact path="/cancellation" component={CancellationReason} />
							<PrivateRoute exact path="/cancellation/new" component={ReasonAdd} />

							<PrivateRoute exact path="/importCSVData" component={ImportCSVData} />

							<PrivateRoute component={HomeRedirect} />
						</Switch>
					</div>

					<Footer />
				</div>
			</div>
		);
	}
}

export default connect()(Dashboard);
