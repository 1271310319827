import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import Widget from '../../shared/Widget';
import Loader from '../../../../shared/components/Loader';

import VehicleApI from '../../../../services/api/vehicleAPI';
import { fetchVehicleList } from '../../../../services/redux/action/vehicleAction';

import './style.css';

class VehicleAdd extends Component {
	state = {
		iconName: null,
		icon: null,
		type: '',
		maxWeight: '',
		maxVolume: '',

		baseFare: 0,
		chargePerKM: 0,
		costPerKG: 0,
		chargePerMin: 0,
		chargePerVolume: 0,

		sending: false,
		vehicleAddSuccess: false,
		vehicleAddFail: false,
		max_volume_in_one_hour: "",
		max_dist_in_one_hour: "",
		max_weight_in_one_hour: "",
		max_weight_in_three_hour: "",
		max_dist_in_three_hour: "",
		max_volume_in_three_hour: "",
		max_weight_in_same_day: "",
		max_dist_in_same_day: "",
		max_volume_in_same_day: "",
		max_weight_in_24_hour: "",
		max_dist_in_24_hour: "",
		max_volume_in_24_hour: "",
		max_weight_in_hrs24to2Days: "",
		max_dist_in_hrs24to2Days: "",
		max_volume_in_hrs24to2Days: "",
		max_weight_in_2_to_7_day: "",
		max_dist_in_2_to_7_day: "",
		max_volume_in_2_to_7_day: "",
		max_weight_in_seven_day_to_14: "",
		max_dist_in_seven_day_to_14: "",
		max_volume_in_seven_day_to_14: "",
		max_weight_in_more_then_17_days: "",
		max_dist_in_more_then_17_days: "",
		max_volume_in_more_then_17_days: "",
	};

	componentDidMount() {
		// this.addVehicle();
	}

	resetForm = () => {
		this.setState({
			iconName: null,
			icon: null,
			type: '',
			maxWeight: '',
			maxVolume: '',
			baseFare: '',
			chargePerKM: '',
			costPerKG: '',
			chargePerMin: '',
			chargePerVolume: '',
			sending: false,
			vehicleAddSuccess: false,
			vehicleAddFail: false,
		});
	};

	addVehicle = () => {
		console.log(this.state)
		VehicleApI.addVehicle(this.state).then((res) => {
			console.log('add Vehicle :', res);
			if (res.errors) {
				this.setState({
					sending: false,
					vehicleAddFail: true,
				});
			}
			if (res.status === 200) {
				this.setState({
					sending: false,
					vehicleAddSuccess: true,
				});
				this.props.dispatch(fetchVehicleList());
			}
		});
		console.log(this.state);
	};

	handleSubmit = (e) => {
		e.preventDefault();
		this.setState({
			sending: true,
			vehicleAddFail: false,
		});
		this.addVehicle();
	};

	handleChange = (e) => {
		if (e.target.value >= 0) {
			this.setState({
				[e.target.id]: e.target.value,
			});
		}
		else {
			this.setState({ [e.target.id]: '' })
		}
	};
	handleTypeChange = (e) => {
		this.setState({
			[e.target.id]: e.target.value
		})
	}
	handleFileChange = (e) => {
		this.setState({
			[e.target.id]: e.target.files[0],
		});
	};

	handleAddMore = (e) => {
		e.preventDefault();
		this.resetForm();
		console.log('handled add more');
	};

	render() {
		let {
			vehicleAddFail,
			vehicleAddSuccess,
			sending,
			iconName,
			type,
			maxWeight,
			maxVolume,
			// baseFare,
			// chargePerKM,
			// costPerKG,
			// chargePerMin,
			// chargePerVolume,
			max_volume_in_one_hour,
			max_dist_in_one_hour,
			max_weight_in_one_hour,
			max_weight_in_three_hour,
			max_dist_in_three_hour,
			max_volume_in_three_hour,
			max_weight_in_same_day,
			max_dist_in_same_day,
			max_volume_in_same_day,
			max_weight_in_24_hour,
			max_dist_in_24_hour,
			max_volume_in_24_hour,
			max_weight_in_hrs24to2Days,
			max_dist_in_hrs24to2Days,
			max_volume_in_hrs24to2Days,
			max_weight_in_2_to_7_day,
			max_dist_in_2_to_7_day,
			max_volume_in_2_to_7_day,
			max_weight_in_seven_day_to_14,
			max_dist_in_seven_day_to_14,
			max_volume_in_seven_day_to_14,
			max_weight_in_more_then_17_days,
			max_dist_in_more_then_17_days,
			max_volume_in_more_then_17_days,

		} = this.state;
		return (
			<Widget className="screen-not-widget" title="Add New Vehicle" size="wide" screenNotWidget={true}>
				<div className="form rise-up-fade-in fill-mode-forwards scroll">
					{!vehicleAddSuccess && (
						<form className="vehicle-form" onSubmit={this.handleSubmit}>
							<label className="hover-rise" htmlFor="icon">
								Vehicle Type
							</label>
							<input
								className="hover-rise"
								type="file"
								id="icon"
								value={iconName}
								onChange={this.handleFileChange}
								required
							/>

							<label className="hover-rise" htmlFor="type">
								Vehicle Type
							</label>
							<input
								className="hover-rise"
								type="text"
								id="type"
								value={type}
								onChange={this.handleTypeChange}
								required
							/>

							<hr />

							<label className="hover-rise" htmlFor="maxWeight">
								Max Weight
							</label>
							<input
								className="hover-rise"
								type="number"
								id="maxWeight"
								min="0"
								value={maxWeight}
								onChange={this.handleChange}
								required
							/>

							<label className="hover-rise" htmlFor="maxVolume">
								Max Volume
							</label>
							<input
								className="hover-rise"
								type="number"
								id="maxVolume"
								min="0"
								value={maxVolume}
								onChange={this.handleChange}
								required
							/>

							{/* <label className="hover-rise" htmlFor="baseFare">
								Base Fare
							</label>
							<input
								className="hover-rise"
								type="number"
								id="baseFare"
								value={baseFare}
								onChange={this.handleChange}
								required
							/>

							<hr />

							<label className="hover-rise" htmlFor="chargePerMin">
								Charge per min
							</label>
							<input
								className="hover-rise"
								type="number"
								id="chargePerMin"
								value={chargePerMin}
								onChange={this.handleChange}
								required
							/>

							<label className="hover-rise" htmlFor="chargePerKM">
								Charge per km
							</label>
							<input
								className="hover-rise"
								type="number"
								id="chargePerKM"
								value={chargePerKM}
								onChange={this.handleChange}
								required
							/>

							<label className="hover-rise" htmlFor="chargePerVolume">
								Charge per Volume
							</label>
							<input
								className="hover-rise"
								type="number"
								id="chargePerVolume"
								value={chargePerVolume}
								onChange={this.handleChange}
								required
							/>

							<label className="hover-rise" htmlFor="costPerKG">
								Cost per kg
							</label>
							<input
								className="hover-rise"
								type="number"
								id="costPerKG"
								value={costPerKG}
								onChange={this.handleChange}
								required
							/> */}

							<hr />

							<table style={{ margin: "30px 0" }}>
								<thead>
									<tr>
										<th></th>
										<th>Max Distance (km)</th>
										<th>Max Weight (kg)</th>
										<th>Max Volume (cubic meter)</th>

									</tr>

								</thead>
								<tbody>
									<tr>
										<td className="text-center"> 1 hour</td>
										<td className="text-center">
											<input
												className="hover-rise"
												type="number"
												id="max_dist_in_one_hour"
												min="0"
												value={max_dist_in_one_hour}
												onChange={this.handleChange}
												required
											/>
										</td>
										<td className="text-center">
											<input
												className="hover-rise"
												type="number"
												id="max_weight_in_one_hour"
												min="0"
												value={max_weight_in_one_hour}
												onChange={this.handleChange}
												required
											/>
										</td>
										<td className="text-center">
											<input
												className="hover-rise"
												type="number"
												id="max_volume_in_one_hour"
												min="0"
												value={max_volume_in_one_hour}
												onChange={this.handleChange}
												required
											/>
										</td>
									</tr>
									<tr>
										<td className="text-center">3 hours </td>
										<td className="text-center">
											<input
												className="hover-rise"
												type="number"
												id="max_dist_in_three_hour"
												min="0"
												value={max_dist_in_three_hour}
												onChange={this.handleChange}
												required
											/>
										</td>
										<td className="text-center">
											<input
												className="hover-rise"
												type="number"
												id="max_weight_in_three_hour"
												min="0"
												value={max_weight_in_three_hour}
												onChange={this.handleChange}
												required
											/>
										</td>
										<td className="text-center">
											<input
												className="hover-rise"
												type="number"
												id="max_volume_in_three_hour"
												min="0"
												value={max_volume_in_three_hour}
												onChange={this.handleChange}
												required
											/>
										</td>
									</tr>
									<tr>
										<td className="text-center"> Same day </td>
										<td className="text-center">
											<input
												className="hover-rise"
												type="number"
												id="max_dist_in_same_day"
												min="0"
												value={max_dist_in_same_day}
												onChange={this.handleChange}
												required
											/>
										</td>
										<td className="text-center">
											<input
												className="hover-rise"
												type="number"
												id="max_weight_in_same_day"
												min="0"
												value={max_weight_in_same_day}
												onChange={this.handleChange}
												required
											/>
										</td>
										<td className="text-center">
											<input
												className="hover-rise"
												type="number"
												id="max_volume_in_same_day"
												min="0"
												value={max_volume_in_same_day}
												onChange={this.handleChange}
												required
											/>
										</td>
									</tr>
									<tr>
										<td className="text-center"> 24 Hour </td>
										<td className="text-center">
											<input
												className="hover-rise"
												type="number"
												id="max_dist_in_24_hour"
												min="0"
												value={max_dist_in_24_hour}
												onChange={this.handleChange}
												required
											/>
										</td>
										<td className="text-center">
											<input
												className="hover-rise"
												type="number"
												id="max_weight_in_24_hour"
												min="0"
												value={max_weight_in_24_hour}
												onChange={this.handleChange}
												required
											/>
										</td>
										<td className="text-center">
											<input
												className="hover-rise"
												type="number"
												id="max_volume_in_24_hour"
												min="0"
												value={max_volume_in_24_hour}
												onChange={this.handleChange}
												required
											/>
										</td>
									</tr>
									<tr>
										<td className="text-center">1-2 Days</td>
										<td className="text-center">
											<input
												className="hover-rise"
												type="number"
												id="max_dist_in_hrs24to2Days"
												min="0"
												value={max_dist_in_hrs24to2Days}
												onChange={this.handleChange}
												required
											/>
										</td>
										<td className="text-center">
											<input
												className="hover-rise"
												type="number"
												id="max_weight_in_hrs24to2Days"
												min="0"
												value={max_weight_in_hrs24to2Days}
												onChange={this.handleChange}
												required
											/>
										</td>
										<td className="text-center">
											<input
												className="hover-rise"
												type="number"
												id="max_volume_in_hrs24to2Days"
												min="0"
												value={max_volume_in_hrs24to2Days}
												onChange={this.handleChange}
												required
											/>
										</td>
									</tr>
									<tr>
										<td className="text-center"> 2-7 Days</td>
										<td className="text-center">
											<input
												className="hover-rise"
												type="number"
												id="max_dist_in_2_to_7_day"
												min="0"
												value={max_dist_in_2_to_7_day}
												onChange={this.handleChange}
												required
											/>
										</td>
										<td className="text-center">
											<input
												className="hover-rise"
												type="number"
												id="max_weight_in_2_to_7_day"
												min="0"
												value={max_weight_in_2_to_7_day}
												onChange={this.handleChange}
												required
											/>
										</td>
										<td className="text-center">
											<input
												className="hover-rise"
												type="number"
												id="max_volume_in_2_to_7_day"
												min="0"
												value={max_volume_in_2_to_7_day}
												onChange={this.handleChange}
												required
											/>
										</td>
									</tr>
									<tr>
										<td className="text-center"> 7-14 Days	</td>
										<td className="text-center">
											<input
												className="hover-rise"
												type="number"
												id="max_dist_in_seven_day_to_14"
												min="0"
												value={max_dist_in_seven_day_to_14}
												onChange={this.handleChange}
												required
											/>
										</td>
										<td className="text-center">
											<input
												className="hover-rise"
												type="number"
												id="max_weight_in_seven_day_to_14"
												min="0"
												value={max_weight_in_seven_day_to_14}
												onChange={this.handleChange}
												required
											/>
										</td>
										<td className="text-center">
											<input
												className="hover-rise"
												type="number"
												id="max_volume_in_seven_day_to_14"
												min="0"
												value={max_volume_in_seven_day_to_14}
												onChange={this.handleChange}
												required
											/>
										</td>
									</tr>
									<tr>
										<td className="text-center">More than 17 days</td>
										<td className="text-center">
											<input
												className="hover-rise"
												type="number"
												id="max_dist_in_more_then_17_days"
												min="0"
												value={max_dist_in_more_then_17_days}
												onChange={this.handleChange}
												required
											/>
										</td>
										<td className="text-center">
											<input
												className="hover-rise"
												type="number"
												id="max_weight_in_more_then_17_days"
												min="0"
												value={max_weight_in_more_then_17_days}
												onChange={this.handleChange}
												required
											/>
										</td>
										<td className="text-center">
											<input
												className="hover-rise"
												type="number"
												id="max_volume_in_more_then_17_days"
												min="0"
												value={max_volume_in_more_then_17_days}
												onChange={this.handleChange}
												required
											/>
										</td>
									</tr>

								</tbody>
							</table>

							<hr />

							{/* <label> */}
							{sending && <Loader />}
							{vehicleAddFail && (
								<div className="vehicle-button-area center-both">
									<p className="center-both fail-text hover-rise fall-down-fade-in fill-mode-forwards text-center ">
										Vehicle Not Added <span class="material-icons center-both">check</span>
									</p>
								</div>
							)}
							<div className="center-both">
								{/* </label> */}

								<input
									type="submit"
									className="center-both login-button add-more-button hover-rise"
									id="submit"
									value={sending ? 'Adding' : vehicleAddFail ? 'Retry' : 'Add Vehicle'}
									required
								/>
							</div>
						</form>
					)}

					{vehicleAddSuccess && (
						<div className="center-both h-100">
							{/* </label> */}
							<div>
								<div className="vehicle-button-area center-both">
									<p className="center-both success-text hover-rise fall-down-fade-in fill-mode-forwards text-center ">
										Vehicle added successfully <span class="material-icons center-both">check</span>
									</p>
								</div>
								<div className="vehicle-button-area">
									<div
										className="center-both add-more-button hover-rise  rise-up-fade-in fill-mode-forwards"
										onClick={this.handleAddMore}
									>
										Add More
									</div>

									<Link to="/vehicle">
										<div className="center-both all-vehicle-button hover-rise  rise-up-fade-in fill-mode-forwards">
											All Vehicles
										</div>
									</Link>
								</div>
							</div>
						</div>
					)}
				</div>
			</Widget>
		);
	}
}

export default connect()(VehicleAdd);
