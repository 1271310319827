import api from '.';

const privayPolicyAPI = {
	fetch,
	create,
	update,
	updatePartial,
	delete_,
};

function fetch(id="") {
	const endpoint = `/extras/getprivacypolicy/${id}`;

	return api
		.get(endpoint)
		.then((response) => {
			return response;
		})
		.catch((error) => {
			return error;
		});
}

function create(data = null) {
	const endpoint = `/extras/getprivacypolicy/`;

	return api
		.post(endpoint, data)
		.then((response) => {
			return response;
		})
		.catch((error) => {
			return error;
		});
}

function update(id = null, data = null) {
	const endpoint = `/extras/getprivacypolicy/${id}`;

	return api
		.put(endpoint, data)
		.then((response) => {
			return response;
		})
		.catch((error) => {
			return error;
		});
}

function updatePartial(id = null, data = null) {
	const endpoint = `/extras/getprivacypolicy/${id}`;

	return api
		.patch(endpoint, data)
		.then((response) => {
			return response;
		})
		.catch((error) => {
			return error;
		});
}

function delete_(id = null) {
	const endpoint = `/extras/getprivacypolicy/${id}`;

	return api
		.delete_(endpoint)
		.then((response) => {
			return response;
		})
		.catch((error) => {
			return error;
		});
}

export default privayPolicyAPI;
