import {
	FETCH_TERMS_CONDITION_LIST,
	FETCH_TERMS_CONDITION_LIST_BEGIN,
	FETCH_TERMS_CONDITION_LIST_SUCCESS,
	FETCH_TERMS_CONDITION_LIST_FAILURE,
	UPDATE_TERMS_CONDITION_LIST,
} from '../action/termsConditionAction';

// const List = [
// 	{
// 		id: 26,
// 		for_vendor: true,
// 		question: 'test question 1 for vendor',
// 		answer: 'test question 1 for vendor',
// 		errors: false,
// 	},
// 	{
// 		id: 23,
// 		for_vendor: true,
// 		question: 'test question 2 for vendor',
// 		answer: 'test question 2 for vendor',
// 		errors: false,
// 	},
// 	{
// 		id: 23,
// 		for_vendor: true,
// 		question: 'test question 3 for vendor',
// 		answer: 'test question 3 for vendor',
// 		errors: false,
// 	},
// ];

const initialState = {
	list: [],
	listError: '',
	listFetchingInProgress: false,
};

function termsCondition(state = initialState, action) {
	switch (action.type) {
		case FETCH_TERMS_CONDITION_LIST:
			return {
				...state,
			};

		case FETCH_TERMS_CONDITION_LIST_BEGIN:
			return {
				...state,
				listFetchingInProgress: true,
			};

		case FETCH_TERMS_CONDITION_LIST_SUCCESS:
			return {
				...state,
				listFetchingInProgress: false,
			};

		case FETCH_TERMS_CONDITION_LIST_FAILURE:
			return {
				...state,
				listError: action.data,
				listFetchingInProgress: false,
			};

		case UPDATE_TERMS_CONDITION_LIST:
			return {
				...state,
				list: action.data,
			};

		default:
			return state;
	}
}

export default termsCondition;
