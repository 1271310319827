import React from 'react';
import { MdThumbUp, MdThumbDown } from 'react-icons/md';
import './orderListComponents.scss';

const Avatar = ({src, alt}) => (
  <span className="avatar">
    <img src={src} alt={alt} />
  </span>
);

const HEADER_COMPONENT = (key) => {
    return (
        <tr key={key}>
            <td style={{ flex: 2 }}>Sender</td>
            <td style={{ flex: 1 }}>Delivery Time</td>
            <td style={{ flex: 1 }}>Distance</td>
            <td style={{ flex: 2 }}>Reciever</td>
            <td style={{ flex: 1 }}>Reciever Phone</td>
        </tr>
    )
}

const ROW_COMPONENT = (data, key, dataLoading) => {
    return (
        <tr className={`vendor-list-row ${dataLoading && 'loading'}`} key={data.id || key}>
            <td className="name" style={{ flex: 2 }}>
                <div>
                    {String(data.sender_name).toLowerCase()}
                </div>

                <div className={`status ${data.completed && 'verified'}`}>
                    {
                        data.completed ?
                            <MdThumbUp />
                            :
                            <MdThumbDown />
                    }
                    <span className='text'>
                        {data.completed ? 'completed' : 'not completed'}
                    </span>
                </div>
            </td>

            <td className={`phone`} style={{ flex: 1 }}>
                <div className={`${!data.delivery_time && 'not-available'}`}>
                    {data.delivery_time || 'not available'}
                </div>
            </td>

            <td className={`phone`} style={{ flex: 1 }}>
                <div className={`${!data.trip_distance && 'not-available'}`}>
                    {data.trip_distance || 'not available'}
                </div>
            </td>

            <td className={`address`} style={{ flex: 2 }}>
                <p className={`${!data.receiver_name && 'not-available'}`}>
                    {data.receiver_name || 'not available'}
                </p>
            </td>

            <td className={`phone`} style={{ flex: 1 }}>
                <div className={`${!data.receiver_phone && 'not-available'}`}>
                    {data.receiver_phone || 'not available'}
                </div>
            </td>
        </tr>
    )
}

const ROW_COMPONENT_LOADING = (data, key) => {
    return (
        <tr key={data.id || key}>
            <td style={{ flex: 1 }}><Avatar src={data.profile_pic} /></td>
            <td style={{ flex: 4 }}>{data.fullname}</td>
            <td style={{ flex: 2 }}>{data.phone_no_registered}</td>
            <td style={{ flex: 4 }}>{data.permanent_address}</td>
        </tr>
    )
}

export {
    HEADER_COMPONENT,
    ROW_COMPONENT,
    ROW_COMPONENT_LOADING,
};