import privacyPolicyAPI from '../../api/privacyPolicyAPI';

export const FETCH_PRIVACY_POLICY_LIST = 'FETCH_PRIVACY_POLICY_LIST';
export const FETCH_PRIVACY_POLICY_LIST_BEGIN = 'FETCH_PRIVACY_POLICY_LIST_BEGIN';
export const FETCH_PRIVACY_POLICY_LIST_SUCCESS = 'FETCH_PRIVACY_POLICY_LIST_SUCCESS';
export const FETCH_PRIVACY_POLICY_LIST_FAILURE = 'FETCH_PRIVACY_POLICY_LIST_FAILURE';
export const UPDATE_PRIVACY_POLICY_LIST = 'UPDATE_PRIVACY_POLICY_LIST';

export function fetchPrivacyPolicyList() {
	return (dispatch) => {
		dispatch(fetchPrivacyPolicyListBegin());
		return privacyPolicyAPI
			.fetch()
			.then((response) => {
                if (response.data.errors) {
                    dispatch(fetchprivacyPolicyListFailure("privacy policy api call exception"));
                } else {
                    dispatch(updatePrivacyPolicyList(response.data.data));
                    dispatch(fetchprivacyPolicyListSuccess());
                }
				return response;
			})
			.catch((error) => {
				dispatch(fetchprivacyPolicyListFailure("privacy policy api call error"));
				return error;
			});
	};
}

export function fetchPrivacyPolicyListBegin() {
	return { type: FETCH_PRIVACY_POLICY_LIST_BEGIN };
}

export function fetchprivacyPolicyListSuccess() {
	return { type: FETCH_PRIVACY_POLICY_LIST_SUCCESS };
}

export function fetchprivacyPolicyListFailure(error) {
	return { type: FETCH_PRIVACY_POLICY_LIST_FAILURE, data: error };
}

export function updatePrivacyPolicyList(newList) {
	return { type: UPDATE_PRIVACY_POLICY_LIST, data: newList };
}
