import React, { Component } from 'react';
import Widget from '../../shared/Widget';
// import ImageViewer from '../../shared/ImageViewer';
import orderAPI from '../../../../services/api/orderAPI';


// const DetailBlock = ({ heading, items }) => {
// 	return (
// 		<div className="block">
// 			<div className="detail-block">
// 				<div className="detail-heading">{heading}</div>
// 				{items.map((item, idx) => {
// 					if (item.image) {
// 						return (
// 							<div key={idx} className={`detail-item center-both hover-rise`}>
// 								<ImageViewer src={item.src} alt={item.alt} width={96} height={72} />
// 							</div>
// 						);
// 					}
// 					else {
// 						return (
// 							<div key={idx} className="detail-item hover-rise">
// 								<span className="detail-label">{item.label}</span>
// 								<span className="detail-text">{item.text}</span>
// 							</div>
// 						);
// 					}
// 				})}
// 			</div>
// 		</div>
// 	);
// };

class VendorDetail extends Component {
	state = {
		id: this.props.match.params.id,
        loading: true,
        data: null,
	};

	componentDidMount() {
		this.fetchOrderDetail();
	}

	fetchOrderDetail() {
		orderAPI.fetch(this.state.id).then((res) => {
			this.setState({
				data: res.data || {},
				loading: false,
            });
            console.log(res);
		});
	}


	render() {
		// const {
		// } = this.state.detail;

		return (
			<Widget
				className="screen-not-widget"
				title="Order Detail"
				size="wide"
				screenNotWidget={true}
				loading={this.state.loading}
			>
				<div className="block-container scroll">


					{/* <div className="block">
						<div className="detail-block">
							<div className="detail-heading">Verification</div>

							<div className="detail-item hover-rise">
								<span className="detail-label">national id</span>
								<span className={`detail-text ${national_id_verified ? 'success-text' : 'fail-text'}`}>{`${national_id_verified ? 'Verified' : 'Not Verified'}`}</span>
							</div>
						</div>
					</div> */}


				</div>
			</Widget>
		);
	}
}

export default VendorDetail;
