const DATA_VERSION_LOG = [
	
	//v1.4.73
	// 10-07-2020

	// v1.4.72
	// 10-07-2020
	
	{
		id: 7,
		version: 'v1.4.7',
		updateDate: '9-07-2020',
		log: [
			`v1.4.7`,
			`-statistics card available on dashboard`,
			`-search feature enabled on tables`,
		],
	},
	{
		id: 7,
		version: 'v1.4.4',
		updateDate: '25-06-2020',
		log: [
			`v1.4.4`,
			`-updated vendor details`,
			`-added orders section`,
		],
	},
	{
		id: 7,
		version: 'v1.4.33',
		updateDate: '18-06-2020',
		log: [
			`v1.4.33`,
			`-added price detail uploading`,
			`-additional info on vendor page`,
			`-added capicity info on vehicle page`,
		],
	},
	{
		id: 6,
		version: 'v1.4.3',
		updateDate: '17-06-2020',
		log: [
			`v1.4.3`,
			`-added document viewer in vendor/vehicle detail`,
			`-added vender verification`,
		],
	},
	{
		id: 5,
		version: 'v1.4.26',
		updateDate: '29-05-2020',
		log: [
			`v1.4.26`,
			`-Added editor for end user agreement`,
			`-fixed ocassional login crash`,
		],
	},
	{
		id: 4,
		version: 'v1.4.253',
		updateDate: '28 -05-2020',
		log: [
			`v1.4.253`,
			`-app version visible on login screen`,
			`-realtime data updates`,
			`-added smooth transitions between screens`
		],
	},
	{
		id: 3,
		version: 'v1.4.252',
		updateDate: '20-05-2020',
		log: [
			`v1.4.252`,
			`-minor bug fixes`,
			`-fixed random app crash`,
		],
	},
	{
		id: 3,
		version: 'v1.4.250',
		updateDate: '16-05-2020',
		log: [
			`v1.4.250`,
			`-Added User Policy management`,
			`-Added faq management`,
			`-Added user agreement management`,
			`-Added Terms & Condition management`,
			`-Reduced bandwidth usage`,
			`-Fixed table widget lag`,
			`-added new animations`,
		],
	},
	{
		id: 3,
		version: 'v1.3.238',
		updateDate: '13-05-2020',
		log: [
			`v1.3.238`,
			`-Added Success/Failure Message for adding vehicle`,
			`-Added "All Vehicle", "Add More" button after Vehicle add success`,
			`-Added new animations for appearing at start`,
		],
	},
	{
		id: 2,
		version: 'v1.3.231',
		updateDate: '12-05-2020',
		log: [
			'v1.3.231',
			`-Added dashboard animations`,
			`-Added list item status borders`,
			`-Fixed scroll stuck bug`,
			`-Added list item status borders`,
			`-Added widget title redirect to pages`,
		],
	},
	{
		id: 1,
		version: 'v1.3.23',
		updateDate: '12-05-2020',
		log: [
			'v1.3.23',
			`-Added Version log widget`,
			`-Vehicle add form aligned`,
			`-Fixed infinite loading`,
		],
	},
];

export default DATA_VERSION_LOG;
