import userAPI from '../../api/userAPI';

export const FETCH_USER_LIST = 'FETCH_User_LIST';
export const FETCH_USER_LIST_BEGIN = 'FETCH_User_LIST_BEGIN';
export const FETCH_USER_LIST_SUCCESS = 'FETCH_User_LIST_SUCCESS';
export const FETCH_USER_LIST_FAILURE = 'FETCH_User_LIST_FAILURE';
export const UPDATE_USER_LIST = 'UPDATE_User_LIST';

export function fetchUserList() {
	return (dispatch) => {
		dispatch(fetchUserListBegin());
		return userAPI
			.fetchUser()
			.then((response) => {
                if (response.data.errors) {
                    dispatch(fetchUserListFailure("api call exception"));
                } else {
                    dispatch(updateUserList(response.data.results));
                    dispatch(fetchUserListSuccess());
                }
				return response;
			})
			.catch((error) => {
				dispatch(fetchUserListFailure("api call error"));
				return error;
			});
	};
}

export function fetchUserListBegin() {
	return { type: FETCH_USER_LIST_BEGIN };
}

export function fetchUserListSuccess() {
	return { type: FETCH_USER_LIST_SUCCESS };
}

export function fetchUserListFailure(error) {
	return { type: FETCH_USER_LIST_FAILURE, data: error };
}

export function updateUserList(newList) {
	return { type: UPDATE_USER_LIST, data: newList };
}
