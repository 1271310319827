import React, { Component } from 'react';
import { connect } from 'react-redux';

import Table from '../../shared/Table';
import Widget from '../../shared/Widget';

import './style.css';

const SETTINGS_VENDOR_TABLE = {
	columns: [
		{
			name: 'Name',
			data_name: 'fullname',
			size: 2,
		},
		{
			name: 'Phone',
			data_name: 'phone_no_registered',
			center: true,
		},
		{
			name: 'National ID',
			data_name: 'national_id_number',
			center: true,
		},
	],
	table_name: 'Vendors',
	size: 'wide',
	profile_pic: 'profile_pic',
};

class Vendor extends Component {
	render() {
		const { vendorList, vendorListFetchingInProgress } = this.props;
		return (
			<Widget className="screen-not-widget" title="Vendor" to="/vendor" size="wide" screenNotWidget={true}>
				<Table
					settings={SETTINGS_VENDOR_TABLE}
					data={vendorList}
					to="/vendor/"
					loading={vendorListFetchingInProgress}
					statusKey="vendor_verified"
				/>
			</Widget>
		);
	}
}

function mapStateToProps(state) {
	return {
		vendorList: state.vendor.list,
		vendorListFetchingInProgress: state.vendor.listFetchingInProgress,
	};
}

export default connect(mapStateToProps)(Vendor);
