import React, { Component } from 'react';
import { Switch, NavLink } from 'react-router-dom';

import PrivateRoute from '../../../../shared/components/PrivateRoute';
import Widget from '../../shared/Widget';

import FAQ from './components/FAQ';
import EndUserAgreement from './components/EndUserAgreement';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsCondition from './components/TermsCondition';

import './style.css';

export const PolicyContent = () => {
	return (
		<div className="policy-tab-container center-both scroll">
			<Switch>
				<PrivateRoute exact path="/policy/enduseragreement" component={EndUserAgreement} />
				<PrivateRoute exact path="/policy/termscondition" component={TermsCondition} />
				<PrivateRoute exact path="/policy/privacypolicy" component={PrivacyPolicy} />
				<PrivateRoute exact component={FAQ} />
			</Switch>
		</div>
	);
};

class Policy extends Component {
	state = {
		currentTab: 'faq',
		page: this.props.match.params.page,
	};

	handleTabClick = (e) => {
		console.log(e.target);
		this.setState({
			currentTab: e.target.id,
		});
	};

	render() {
		// const { page } = this.state;
		return (
			<Widget className="screen-not-widget" title="Policies" size="wide" screenNotWidget={true}>
				<div className="policy">
					<div className="policy-nav">
						<ul>
							<NavLink className="policy-nav-item" to="/policy/faq">
								faq
							</NavLink>

							<NavLink className="policy-nav-item" to="/policy/enduseragreement">
								user agreement (in development)
							</NavLink>

							<NavLink className="policy-nav-item" to="/policy/privacypolicy">
								privacy policy
							</NavLink>

							<NavLink className="policy-nav-item" to="/policy/termsCondition">
								terms & condition
							</NavLink>
						</ul>
					</div>

					<PolicyContent/>
				</div>
			</Widget>
		);
	}
}

export default Policy;
