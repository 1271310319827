import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Loader from '../../../../shared/components/Loader';

import './style.css';

const Titlebar = ({ title, to , screenNotWidget }) => {
	return (
		<Link to={to}>
			<div className={`widget-titlebar ${screenNotWidget ? 'screen-not-widget' : ''}`}>{title}</div>
		</Link>
	);
};

const Redirect = ({ to }) => {
	return (
		<div className="widget-redirect">
			<Link to={to}>
				<span className="widget-redirect-icon material-icons">arrow_forward</span>
			</Link>
		</div>
	);
};

const Widget = ({ title, to="#", children, size, screenNotWidget, loading }) => {
	const [
		appear,
	] = useState(
		[
			'rise-up-fade-in',
			'fall-down-fade-in',
			'right-fade-in',
			'left-fade-in',
		][Math.floor(Math.random() * 4)],
	);
	const [
		appearSpeed
	] = useState(String(Math.random() * (0.9 - -0.1) + 0.1) + 's !important');

	// console.log('appear speed', appearSpeed);
	
	size = size === 'big' ? 'card-wide card-tall' : size === 'wide' ? 'card-wide' : size === 'tall' ? ' card-tall' : '';
	// const appearList = ["rise-up-fade-in", "fall-down-fade-in", "right-fade-in", "left-fade-in" ];
	// const appear =
	return (
		<div
			className={`${appear} appear-fast card widget card-hover hover-rise uncopyable ${size}  ${screenNotWidget
				? 'screen-not-widget'
				: ''}`}
			style={{
				animationDuration: { appearSpeed },
			}}
		>
			{title && <Titlebar title={title} to={to} screenNotWidget={screenNotWidget} />}
			{loading ? (
				<div className="center-both w-100 h-100">
					<Loader className="center-both" />
				</div>
			) : (
				<div className="widget-body">{children}</div>
			)}
			{!screenNotWidget && to && <Redirect to={to} />}
		</div>
	);
};

export default Widget;
