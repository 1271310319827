import { combineReducers } from "redux";
import vendor from "./vendorReducer";
import vehicle from "./vehicleReducer";
import user from './userReducer';
import faq from './faqReducer';
import endUserAgreement from './endUserAgreementReducer';  
import privacyPolicy from './privacyPolicyReducer';  
import termsCondition from './termsConditionReducer';  
import order from './orderReducer';  

export default combineReducers({
	vendor,
	vehicle,
	user,
	faq,
	endUserAgreement,
	privacyPolicy,
	termsCondition,
	order,
});