import orderAPI from '../../api/orderAPI';

export const FETCH_ORDER_LIST = 'FETCH_ORDER_LIST';
export const FETCH_ORDER_LIST_BEGIN = 'FETCH_ORDER_LIST_BEGIN';
export const FETCH_ORDER_LIST_SUCCESS = 'FETCH_ORDER_LIST_SUCCESS';
export const FETCH_ORDER_LIST_FAILURE = 'FETCH_ORDER_LIST_FAILURE';
export const UPDATE_ORDER_LIST = 'UPDATE_ORDER_LIST';

export function fetchOrderList() {
	return (dispatch) => {
		dispatch(fetchOrderListBegin());
		return orderAPI
			.fetch()
			.then((response) => {
                if (response.data.errors) {
                    dispatch(fetchOrderListFailure("api call exception"));
                } else {
                    dispatch(updateOrderList(response.data.results));
                    dispatch(fetchOrderListSuccess());
                }
				return response;
			})
			.catch((error) => {
				dispatch(fetchOrderListFailure("api call error"));
				return error;
			});
	};
}

export function fetchOrderListBegin() {
	return { type: FETCH_ORDER_LIST_BEGIN };
}

export function fetchOrderListSuccess() {
	return { type: FETCH_ORDER_LIST_SUCCESS };
}

export function fetchOrderListFailure(error) {
	return { type: FETCH_ORDER_LIST_FAILURE, data: error };
}

export function updateOrderList(newList = []) {
	return { type: UPDATE_ORDER_LIST, data: newList };
}
