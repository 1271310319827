import React, { Component } from 'react';
import { connect } from 'react-redux';

import Loader from '../../../../../shared/components/Loader';
import endUserAgreementApI from '../../../../../services/api/endUserAgreementAPI';
import { fetchEndUserAgreementList } from '../../../../../services/redux/action/endUserAgreementAction';

// import Table from '../../../shared/Table';

import RichEditor from '../../../shared/RichEditor';

import './style.css';
import './radioButton.css';

// const SETTINGS_END_USER_AGREEMENT_TABLE = {
// 	columns: [
// 		{
// 			name: 'title',
// 			data_name: 'title',
// 		},
// 		{
// 			name: 'content',
// 			data_name: 'content',
// 		},
// 	],
// };

class FAQAdd extends Component {
	state = {
		title: '',
		content: '',
		faqAddSuccess: false,
		faqAddFail: false,
		sending: false,
		vendorHtml: '',
	};

	componentDidMount() {
		let data  ={
			"title":" end user agreemtndklgajskl",
			"content":"fkjaslfjafadfafasfasgskfskfjdlfslf"
			};
		endUserAgreementApI.fetch('?for=vendor', data).then(
			res=>{
				 console.log('end user agree',res)
				 this.setState({
					 vendorHtml: res.data.data[0].content,
				 })
			}
		)
	}

	handleVendorHtmlChange(hmtl) {
		this.setState({
			vendorHtml: '',
		})
	}

	resetForm = () => {
		this.setState({
			title: '',
			content: '',
			faqAddSuccess: false,
			faqAddFail: false,
			sending: false,
		});
	};

	addFAQ = () => {
		const data = {
			title: this.state.title,
			content: this.state.content,
		};

		endUserAgreementApI
			.create(data)
			.then((response) => {
				if (response.errors) {
					this.setState({
						loading: false,
						faqAddFail: true,
					});
				}
				else {
					this.setState({
						loading: false,
						faqAddSuccess: true,
					});
					this.props.dispatch(fetchEndUserAgreementList());
				}
			})
			.catch(() => {
				this.setState({
					loading: false,
					faqAddFail: true,
				});
			});
	};

	handleSubmit = (e) => {
		e.preventDefault();
		this.setState({
			sending: true,
			faqAddFail: false,
		});
		this.addFAQ();
	};

	handleChange = (e) => {
		console.log(e.target.value);
		// e.target.checked = 'checked';
		this.setState({
			[e.target.id]: e.target.value,
		});
	};

	handleAddAnother = (e) => {
		e.preventDefault();
		this.resetForm();
	};

	render() {
		const { title, 
			// content,
			 faqAddFail, faqAddSuccess, sending } = this.state;
		return (
			<div className="form w-100 h-100 rise-up-fade-in fill-mode-forwards scroll">
				{!faqAddSuccess && (
					<form className="policy-form vehicle-form" onSubmit={this.handleSubmit} autoComplete="off">
						<label className="hover-rise" htmlFor="title">
							Title
						</label>
						<input
							className="hover-rise scroll"
							type="text"
							id="title"
							value={title}
							onChange={this.handleChange}
							required
						/>

						<label className="hover-rise" htmlFor="content">
							Content
						</label>

						<RichEditor html={this.state.vendorHtml} handleHtmlChange={(html) => this.handleVendorHtmlChange(html)}/>
						{/* <textarea
							className="hover-rise scroll"
							type="text"
							id="content"
							value={content}
							onChange={this.handleChange}
							required
						/> */}

						{/* <label> */}
						{sending && <Loader />}
						{faqAddFail && (
							<div className="vehicle-button-area center-both">
								<p className="center-both fail-text hover-rise fall-down-fade-in fill-mode-forwards text-center ">
									User Agreement Not Added <span class="material-icons center-both">check</span>
								</p>
							</div>
						)}
						<div className="center-both">
							{/* </label> */}

							{/* <input
								type="submit"
								className="center-both login-button add-more-button hover-rise"
								id="submit"
								value={sending ? 'Adding' : faqAddFail ? 'Retry' : 'Add'}
								required
							/> */}
						</div>
					</form>
				)}

				{/* {faqAddSuccess && (
					<div className="center-both h-100">
						<div>
							<div className="vehicle-button-area center-both">
								<p className="center-both success-text hover-rise fall-down-fade-in fill-mode-forwards text-center ">
									User Agreement Added Successfully{' '}
									<span class="material-icons center-both">check</span>
								</p>
							</div>
							<div className="vehicle-button-area">
								<div
									className="center-both add-more-button hover-rise  rise-up-fade-in fill-mode-forwards"
									onClick={this.handleAddAnother}
								>
									Add Another
								</div>
							</div>
						</div>
					</div>
				)} */}
			</div>
		);
	}
}

const EndUserAgreement = ({ endUserAgreementList, endUserAgreementListFetchingInProgress, dispatch }) => {
	return (
		<div className="policy-tab-content block-container">
			<div className="block">
				<div className="detail-heading">Add User Agreement</div>
				<FAQAdd dispatch={dispatch} />
			</div>
			<div className="block">
				<div className="detail-heading">Preview</div>
				{/* <Table
					settings={SETTINGS_END_USER_AGREEMENT_TABLE}
					data={endUserAgreementList}
					loading={endUserAgreementListFetchingInProgress}
					statusKey="id"
				/> */}
				Placeholder
			</div>
		</div>
	);
};

function mapStateToProps(state) {
	return {
		endUserAgreementContentVendor: state.endUserAgreement.con,
		endUserAgreementContentVendorUser: state.endUserAgreement.list,
		endUserAgreementListFetchingInProgress: state.endUserAgreement.listFetchingInProgress,
	};
}

export default connect(mapStateToProps)(EndUserAgreement);
