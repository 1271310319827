import React, { Component } from 'react';
import api from '../../../../services/api';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import Table from '../../shared/Table';
import Widget from '../../shared/Widget';


const CANCELLATION_REASON_CARD = {
  columns: [
    {
      name: 'User Type',
      data_name: 'user_type',
      size: 1,
    },
    {
      name: 'Reason Code',
      data_name: 'reason_code',
      center: true,
    },
    {
      name: 'Reason Description',
      data_name: 'reason_description',
      center: true,
    },
  ],
  table_name: 'Cancellation Reasons',
  size: 'wide',
  profile_pic: 'profile_pic',
};

class CancellationReason extends Component {
  state = {
    reasons: []
  };


  componentDidMount = () => {
    api.get('order/cancellation_reason/').then(res => {
      this.setState({ reasons: res.data.data })
    })
  }


  render() {
    const { reasons } = this.state
    return (
      <React.Fragment>
        <Link to="./cancellation/new">
          <div className="center-both">
            <div className="center-both all-vehicle-button hover-rise  rise-up-fade-in fill-mode-forwards">
              Add Cancellation Reason
						</div>
          </div>
        </Link>

        <Widget className="screen-not-widget" title="Cancellation Reasons" size="wide" screenNotWidget={true}>
          <Table
            settings={CANCELLATION_REASON_CARD}
            data={reasons}
            to="/cancellation/"
            loading={this.props.vehicleListFetchingInProgress}
          />
        </Widget>
      </React.Fragment>

    );
  }
}
function mapStateToProps(state) {
  return {
    vehicleListFetchingInProgress: state.vehicle.listFetchingInProgress,
  };
}

export default connect(mapStateToProps)(CancellationReason);

