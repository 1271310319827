import React, { Component } from 'react';
import { connect } from 'react-redux';

import Loader from '../../../../../shared/components/Loader';
import termsConditionAPI from '../../../../../services/api/termsConditionAPI';
import { fetchTermsConditionList } from '../../../../../services/redux/action/termsConditionAction';

import Table from '../../../shared/Table';

import './style.css';
import './radioButton.css';

const SETTINGS_TERMS_CONDITION_TABLE = {
	columns: [
		{
			name: 'content',
			data_name: 'content',
		},
	],
};

class TermsConditionAdd extends Component {
	state = {
        content: '',
        policyFor: '',
		termsConditionAddSuccess: false,
		termsConditionAddFail: false,
		sending: false,
	};

	resetForm = () => {
		this.setState({
			content: '',
			termsConditionAddSuccess: false,
			termsConditionAddFail: false,
			sending: false,
		});
	};

	addFAQ = () => {
		const data = {
            content: this.state.content,
            for_vendor: this.state.policyFor === 'vendor' ? true : false,
        };
        
		termsConditionAPI
			.create(data)
			.then((response) => {
				if (response.errors) {
					this.setState({
						loading: false,
						termsConditionAddFail: true,
					});
				}
				else {
					this.setState({
						loading: false,
						termsConditionAddSuccess: true,
					});
					this.props.dispatch(fetchTermsConditionList());
				}
			})
			.catch(() => {
				this.setState({
					loading: false,
					termsConditionAddFail: true,
				});
			});
	};

	handleSubmit = (e) => {
		e.preventDefault();
		this.setState({
			sending: true,
			termsConditionAddFail: false,
		});
		this.addFAQ();
	};

	handleChange = (e) => {
		console.log(e.target.value);
		// e.target.checked = 'checked';
		this.setState({
			[e.target.id]: e.target.value,
		});
	};

	handleAddAnother = (e) => {
		e.preventDefault();
		this.resetForm();
	};

	render() {
		const { 
			// title,
			 content, termsConditionAddFail, termsConditionAddSuccess, sending } = this.state;
		return (
			<div className="form w-100 h-100 rise-up-fade-in fill-mode-forwards scroll">
				{!termsConditionAddSuccess && (
					<form className="policy-form vehicle-form" onSubmit={this.handleSubmit} autocomplete="off">
						<label className="hover-rise" htmlFor="content">
							Content
						</label>
						<textarea
							className="hover-rise scroll"
							type="text"
							id="content"
							value={content}
							onChange={this.handleChange}
							required
						/>

						<label className="container">
							Vendor
							<input
								type="radio"
								id="policyFor"
								name="policyFor"
								value="vendor"
								onClick={this.handleChange}
								// onChange={this.handleChange}
								required
							/>
							<span className="checkmark" />
						</label>
						<label className="container">
							User
							<input
								type="radio"
								id="policyFor"
								name="policyFor"
								value="user"
								onClick={this.handleChange}
								// onChange={this.handleChange}
								required
							/>
							<span className="checkmark" />
						</label>

						{/* <label> */}
						{sending && <Loader />}
						{termsConditionAddFail && (
							<div className="vehicle-button-area center-both">
								<p className="center-both fail-text hover-rise fall-down-fade-in fill-mode-forwards text-center ">
									Term/Condition Not Added <span class="material-icons center-both">check</span>
								</p>
							</div>
						)}
						<div className="center-both">
							{/* </label> */}

							<input
								type="submit"
								className="center-both login-button add-more-button hover-rise"
								id="submit"
								value={sending ? 'Adding' : termsConditionAddFail ? 'Retry' : 'Add'}
								required
							/>
						</div>
					</form>
				)}

				{termsConditionAddSuccess && (
					<div className="center-both h-100">
						{/* </label> */}
						<div>
							<div className="vehicle-button-area center-both">
								<p className="center-both success-text hover-rise fall-down-fade-in fill-mode-forwards text-center ">
									Term/Condition Added Successfully{' '}
									<span class="material-icons center-both">check</span>
								</p>
							</div>
							<div className="vehicle-button-area">
								<div
									className="center-both add-more-button hover-rise  rise-up-fade-in fill-mode-forwards"
									onClick={this.handleAddAnother}
								>
									Add Another
								</div>
							</div>
						</div>
					</div>
				)}
			</div>
		);
	}
}

const TermsCondition = ({ termsConditionList, termsConditionListFetchingInProgress, dispatch }) => {
	return (
		<div className="policy-tab-content block-container">
			<div className="block">
				<div className="detail-heading">Add Term/Condition</div>
				<TermsConditionAdd dispatch={dispatch} />
			</div>
			<div className="block">
				<div className="detail-heading">terms & conditions</div>
				<Table
					settings={SETTINGS_TERMS_CONDITION_TABLE}
					data={termsConditionList}
					loading={termsConditionListFetchingInProgress}
					statusKey="id"
				/>
			</div>
		</div>
	);
};

function mapStateToProps(state) {
	return {
		termsConditionList: state.termsCondition.list,
		termsConditionListFetchingInProgress: state.termsCondition.listFetchingInProgress,
	};
}

export default connect(mapStateToProps)(TermsCondition);
