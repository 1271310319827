import faqAPI from '../../api/faqAPI';

export const FETCH_FAQ_LIST = 'FETCH_FAQ_LIST';
export const FETCH_FAQ_LIST_BEGIN = 'FETCH_FAQ_LIST_BEGIN';
export const FETCH_FAQ_LIST_SUCCESS = 'FETCH_FAQ_LIST_SUCCESS';
export const FETCH_FAQ_LIST_FAILURE = 'FETCH_FAQ_LIST_FAILURE';
export const UPDATE_FAQ_LIST = 'UPDATE_FAQ_LIST';

export function fetchFAQList() {
	return (dispatch) => {
		dispatch(fetchFAQListBegin());
		return faqAPI
			.fetch()
			.then((response) => {
                if (response.data.errors) {
                    dispatch(fetchFAQListFailure("api call exception"));
                } else {
                    dispatch(updateFAQList(response.data.data));
                    dispatch(fetchFAQListSuccess());
                }
				return response;
			})
			.catch((error) => {
				dispatch(fetchFAQListFailure("api call error"));
				return error;
			});
	};
}

export function fetchFAQListBegin() {
	return { type: FETCH_FAQ_LIST_BEGIN };
}

export function fetchFAQListSuccess() {
	return { type: FETCH_FAQ_LIST_SUCCESS };
}

export function fetchFAQListFailure(error) {
	return { type: FETCH_FAQ_LIST_FAILURE, data: error };
}

export function updateFAQList(newList) {
	return { type: UPDATE_FAQ_LIST, data: newList };
}
