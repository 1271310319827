import React from 'react';
import Viewer from 'react-viewer';
import defaultImage from '../../../../shared/images/misc/image_not_available.png';

const ImageViewer = ({ src = "", alt = "", width = 64, height = 64 }) => {
  const [visible, setVisible] = React.useState(false);

  return (
    <div>
      <img width={width} height={height} onClick={() => { setVisible(true); }} src={src || defaultImage} alt={alt} onError={(e) => {
        if (e.target.src !== defaultImage) e.target.src = defaultImage;
      }} />
      <Viewer
        visible={visible}
        onClose={() => { setVisible(false); }}
        images={[{ src, alt }]}
        defaultImg={{ src: defaultImage, width: 240, height: 180 }}
      />
    </div>
  );
}

export default ImageViewer