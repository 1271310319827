import React, { Component } from "react";
import { connect } from "react-redux";

// import Table from "../../shared/Table";
import Widget from "../../shared/Widget";

import TablePaginated from "../../shared/TablePaginated/Table";

import orderAPI from "../../../../services/api/orderAPI";
import {
  HEADER_COMPONENT,
  ROW_COMPONENT,
  ROW_COMPONENT_LOADING,
} from "./OrderListComponent";

const COLUMN = [
  {
    name: "sender",
    dataKey: "sender_name",
    size: 2,
  },
  {
    name: "reciever",
    dataKey: "receiver_name",
    size: 1,
  },
  {
    name: "gender",
    dataKey: "gender",
    size: 1,
  },
];

// const SETTINGS_ORDER_TABLE = {
//   columns: [
//     {
//       name: "Sender",
//       data_name: "sender_name",
//       size: 2,
//     },
//     {
//       name: "type",
//       data_name: "type_of_delivery",
//       center: true,
//     },
//     {
//       name: "Date Created",
//       data_name: "creation_time",
//       center: true,
//     },
//     {
//       name: "Accuracy",
//       data_name: "accuracy",
//     },
//     {
//       name: "Distance",
//       data_name: "trip_distance",
//       center: true,
//     },

//     {
//       name: "Delivery time",
//       data_name: "scheduled_delivery_time",
//       center: true,
//     },

//     {
//       name: "Pickup Time",
//       data_name: "scheduled_pickup_time",
//       center: true,
//     },
//   ],
//   table_name: "Orders",
//   size: "wide",
// };

class Order extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchQuery: "",
      currentPage: 0,
      pageCount: 0,
      orderList: [],
      orderListFetchingInProgress: false,
      itemsPerPageCount: 0,
    };
  }

  componentDidMount() {
    this.setState({ orderListFetchingInProgress: true });
    orderAPI
      .fetch()
      .then((res) => {
        const orderList = res.data.results;
        const itemsPerPageCount = orderList.length;
        const currentPage = orderList ? 1 : 0;
        const pageCount = itemsPerPageCount
          ? Math.ceil(res.data.count / itemsPerPageCount)
          : 0;
        console.log("poop", { orderList });
        this.setState({
          orderList,
          itemsPerPageCount,
          currentPage,
          pageCount,
          orderListFetchingInProgress: false,
        });
      })
      .catch((err) => {
        this.setState({ orderListFetchingInProgress: false });
      });
  }

  setSearchQuery = (searchQuery) => {
    this.setState({ searchQuery });
  };

  setCurrentPage = (currentPage) => {
    this.setState({ currentPage, orderListFetchingInProgress: true });
    orderAPI
      .fetchFromPage(currentPage)
      .then((res) => {
        const orderList = res.data.results;
        console.log("poop", { orderList });
        this.setState({ orderList, orderListFetchingInProgress: false });
      })
      .catch((err) => {
        this.setState({ orderListFetchingInProgress: false });
      });
  };

  render() {
    const {
      searchQuery,
      currentPage,
      pageCount,
      orderList,
      orderListFetchingInProgress,
    } = this.state;

    return (
      <Widget
        className="screen-not-widget"
        title="Order"
        size="wide"
        screenNotWidget={true}
      >

        {/* <Table
					settings={SETTINGS_ORDER_TABLE}
					data={orderList}
					// to="/order/"
					to="#"
					loading={orderListFetchingInProgress}
					statusKey={'expire'}
					statusKeyReverse={true}
				/> */}
        <TablePaginated
          columns={COLUMN}
          data={orderList}
          dataLoading={orderListFetchingInProgress}
          rowComponent={ROW_COMPONENT}
          rowComponentLoading={ROW_COMPONENT_LOADING}
          headerComponent={HEADER_COMPONENT}
          currentPage={currentPage}
          pageCount={pageCount}
          setCurrentPage={this.setCurrentPage}
          searchQuery={searchQuery}
          setSearchQuery={this.setSearchQuery}
          rowLink="/order/"
          rowLinkAccessor="id"
        />
      </Widget>
    );
  }
}

function mapStateToProps(state) {
  return {
    orderList: state.order.list,
    orderListFetchingInProgress: state.order.listFetchingInProgress,
  };
}

export default connect(mapStateToProps)(Order);
