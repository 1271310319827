import React, { Component } from 'react';
import { connect } from 'react-redux';
import StatCard from './StatCard';
import Widget from '../../shared/Widget';
import Table from '../../shared/Table';
// import PolicyContent from '../Policy';

// import { Editor } from 'react-draft-wysiwyg';
// import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
// import { EditorState, convertToRaw, ContentState } from 'draft-js';
// import draftToHtml from 'draftjs-to-html';
// import htmlToDraft from 'html-to-draftjs';

import { DATA_NOTIFICATION_CARD } from './dummtData';
import DATA_VERSION_LOG from './versionLog';
import {
	SETTINGS_NOTIFICATION_CARD,
	SETTINGS_USER_CARD,
	SETTINGS_VEHICLE_CARD,
	SETTINGS_VENDOR_CARD,
	SETTINGS_ORDER_CARD,
	SETTINGS_VERSION_LOG,
} from './settings';


window.scrollTo(0,200);

class Home extends Component {
	render() {
		return (
			<React.Fragment>
				<StatCard 
					vendorCount={this.props.vendorList.length}
					venderLoading={this.props.vendorListFetchingInProgress}
					userCount={this.props.userList.length}
					userLoading={this.props.userListFetchingInProgress}
					orderCount={this.props.orderList.length}
					orderLoading={this.props.orderListFetchingInProgress}
				/>

				<div className="card-container scroll grid">
					<Widget title="Vendor" to="/vendor" size="wide">
						<Table
							settings={SETTINGS_VENDOR_CARD}
							data={this.props.vendorList}
							to="/vendor/"
							loading={this.props.vendorListFetchingInProgress}
							statusKey={'vendor_verified'}
						/>
					</Widget>

					<Widget title="User" to="/user" size="wide">
						<Table
							settings={SETTINGS_USER_CARD}
							data={this.props.userList}
							to="/user/"
							loading={this.props.userListFetchingInProgress}
							statusKey="email_verified"
						/>
					</Widget>

					<Widget title="Notification" to="#" size="tall">
						<Table
							settings={SETTINGS_NOTIFICATION_CARD}
							data={DATA_NOTIFICATION_CARD}
							// to="/"
							loading={DATA_NOTIFICATION_CARD < 1}
						/>
					</Widget>
					<Widget title="Vehicle" to="/vehicle" size="wide">
						<Table
							settings={SETTINGS_VEHICLE_CARD}
							data={this.props.vehicleList}
							to="/vehicle/"
							loading={this.props.vehicleListFetchingInProgress}
						/>
					</Widget>

					{/* <Widget title="Policy Manager (in development)" size="big" to="/policy/faq">
					</Widget> */}

					{/* <Widget title="Overview (in development)" size="tall" loading={true} /> */}
					
					<Widget title="Order" to="/order" size="wide">
						<Table
							settings={SETTINGS_ORDER_CARD}
							data={this.props.orderList}
							to="/order/"
							loading={this.props.orderListFetchingInProgress}
							statusKey={'expire'}
							statusKeyReverse={true}
						/>
					</Widget>

					{/* <Widget title="Dashboard Manager (in development)" size="" loading={true} /> */}

					<Widget title="App Updates" size="tall">
						<Table
							settings={SETTINGS_VERSION_LOG}
							data={DATA_VERSION_LOG}
							loading={!DATA_VERSION_LOG}
							statusKey="id"
						/>
					</Widget>

					{/* <Widget title="Recent Activities (in development)" size="tall" loading={true} /> */}


					{/* <Widget title="Editor Test" size="big"> */}
					{/* <Editor
							// editorState={editorState}
							toolbarClassName="toolbarClassName"
							wrapperClassName="wrapperClassName"
							editorClassName="editorClassName"
							// onEditorStateChange={this.onEditorStateChange}
							onChange={(e) => {
								// const rawContentState = convertToRaw(e.blocks);

								// const markup = draftToHtml(rawContentState);
								// console.log(e);
								// console.log(rawContentState);
								// console.log(markup);
							}}
							toolbarHidden={false}
						/> */}

					{/* <EditorConvertToHTML/> */}
					{/* </Widget> */}


				</div>
			</React.Fragment>
		);
	}
}

function mapStateToProps(state) {
	return {
		vendorList: state.vendor.list,
		vendorListFetchingInProgress: state.vendor.listFetchingInProgress,
		vehicleList: state.vehicle.list,
		vehicleListFetchingInProgress: state.vehicle.listFetchingInProgress,
		userList: state.user.list,
		userListFetchingInProgress: state.user.listFetchingInProgress,
		orderList: state.order.list,
		orderListFetchingInProgress: state.order.listFetchingInProgress,
	};
}

export default connect(mapStateToProps)(Home);
