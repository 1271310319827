import {
	FETCH_VENDOR_LIST,
	FETCH_VENDOR_LIST_BEGIN,
	FETCH_VENDOR_LIST_SUCCESS,
	FETCH_VENDOR_LIST_FAILURE,
	UPDATE_VENDOR_LIST,
} from '../action/vendorAction';

// const vendorList = [
// 	{
// 		id: 1,
// 		fullname: 'paymenaad',
// 		dob: '1990-09-09',
// 		gender: 'Male',
// 		phone_no_registered: '+919752891891',
// 		permanent_address: 'Indore',
// 		national_id_number: '12eqwd',
// 		national_id:
// 			'https://storage.googleapis.com/jurnix-user-profile-dev/user_7/national_id/national_id.jpg?Expires=1589571013&GoogleAccessId=storage-admin-dev-32%40proud-maker-274213.iam.gserviceaccount.com&Signature=MSR4uioWA24d0VyuVhKZ2waES%2BfkFVjGBu9an2dalnKka4cNCmFh%2FIFdFXXJBmjH9S9Vwn5kRlVX1byvOj5f7mPe8GU8kTs9w1tKx3M1hlBnStafBsKHJOfeuVVJ1r0%2FgIQsi5ChnYoUSRs10L7hmjLLWTvaa0C%2FjQcAcvf%2BXbqzZVIn1B%2BUm886O7T2I%2Faeky9B2q6uOb1DPZzrUo5vIOCqZQK%2F3VevabnUI3eoUaScyBlWQqFGI4TVgQt4Pc4FGe%2Bx21KpZ1TShQs0RwEk3w4r0ZPm6sN%2FuwUTsXESFpRT%2BGJj9VDYHAk9Z6yZIpQLv3SWKN%2BCwXV4gmfqMyAhlg%3D%3D',
// 		is_reviewed: true,
// 		national_id_verified: true,
// 		national_id_admin_comm: null,
// 		vendor_verified: true,
// 		profile_pic:
// 			'https://storage.googleapis.com/jurnix-user-profile-dev/user_7/profile_pic/profile_pic.jpg?Expires=1589571013&GoogleAccessId=storage-admin-dev-32%40proud-maker-274213.iam.gserviceaccount.com&Signature=Ow0SzcZ2DlVQyw4tqUZc9ZEIKjMPLF2QrwGnykJLFRdchcgzJ6poX9EZC85D0IbraUZZKwCqidEwXbMeBiljH%2Fotyo7TG4IfvVEo%2BhizceNgWYgpYQAXr5gaiFpBDJxXJXWGgOdgMZ8tKvoiC3VO%2F3NR58yGZkFZkW86zGQjdzTbxDsJZBCJuPVvl9wwfAmWCo%2BpmMFDs54OuKCxwGqUwbYiSROODH9yzv46is9uJztmL1S8OcFT8%2B5A6RSYOg5tqtg38aHo0GuVtcnoY%2Bohf%2F32siZjBo%2FD7ag2Bh4%2B4hKKjThLjHeTl17I6jlcg9lWz2%2B%2FtVX4N%2BFOjTwetCbFoQ%3D%3D',
// 		User: 7,
// 	},
// ];

const initialState = {
	list: [],
	listError: '',
	listFetchingInProgress: false,
};

function vendor(state = initialState, action) {
	switch (action.type) {
		case FETCH_VENDOR_LIST:
			return {
				...state,
			};

		case FETCH_VENDOR_LIST_BEGIN:
			return {
				...state,
				listFetchingInProgress: true,
			};

		case FETCH_VENDOR_LIST_SUCCESS:
			return {
				...state,
				listFetchingInProgress: false,
			};

		case FETCH_VENDOR_LIST_FAILURE:
			return {
				...state,
				listError: action.data,
				listFetchingInProgress: false,
			};

		case UPDATE_VENDOR_LIST:
			return {
				...state,
				list: action.data,
			};

		default:
			return state;
	}
}

export default vendor;
