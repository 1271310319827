import React from 'react';
import { Route , Switch } from 'react-router-dom';

import './shared/styles/card.css';
import './shared/styles/utils.css';
import './shared/styles/scroll.css';
import './shared/styles/hover.css';
import './shared/styles/button.css';
import './shared/styles/animations.css';

import Login from './screens/Login';
import Dashboard from './screens/Dashboard';
import PrivateRoute from './shared/components/PrivateRoute';

function App() {
	return (
		<div className="App">
			<div>
				<Switch>
					<Route exact path="/login" component={Login} />
					<PrivateRoute component={Dashboard} />
				</Switch>
			</div>
		</div>
	);
}

export default App;
