import React , {Component} from 'react';

import { Editor } from 'react-draft-wysiwyg';
import { convertToRaw } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {EditorState,
	//  convertToRaw,
	  ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';


class RichEditor extends Component {
	state = {
		editorState: null,
		html: '',
	};

	componentDidMount() {
		// const html = '<p>Placeholderaffasfasf</p>';
		const contentBlock = htmlToDraft(this.props.html);
		if (contentBlock) {
		//   const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
		//   const editorState = EditorState.createWithContent(contentState);
		//   this.setState({
		// 	editorState,
		//   });
			
		}
		this.toRawState();
	}

	componentWillReceiveProps() {
		this.toRawState();
		this.setState({
			html: this.props.hmtl,
		});
	}

	toRawState() {
		const blocksFromHtml = htmlToDraft(this.props.html);
		const { contentBlocks, entityMap } = blocksFromHtml;
		const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
		const editorState = EditorState.createWithContent(contentState);
		this.setState({
			editorState,
		})
		console.log('state', editorState,'html', this.state.html);
	}

	toHtml() {
		if (!this.state.editorState)
			return;

		const rawContentState = convertToRaw(this.state.editorState.getCurrentContent());
		const html = draftToHtml(
			rawContentState, 
			// hashtagConfig, 
			// directional, 
			// customEntityTransform
		);
		console.log('html', html, 'state', this.state.editorState);
	}
	
  
	onEditorStateChange = (editorState) => {
	  this.setState({
		editorState,
	  });
	  this.toHtml();
	};
  
	render() {
	  const { editorState } = this.state;
	  return (
		<div>
		  <Editor
			editorState={editorState}
			wrapperClassName="demo-wrapper"
			editorClassName="demo-editor"
			onEditorStateChange={this.onEditorStateChange}
		  />
		  {/* <textarea
			disabled
			value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
		  /> */}
		</div>
	  );
	}
  }

  export default RichEditor;