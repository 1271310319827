import React, { Component } from 'react';
// import { Switch, NavLink } from 'react-router-dom';
// import Papa from 'papaparse';
import { CSVReader } from 'react-papaparse'

import Widget from '../../shared/Widget';
import Loader from '../../../../shared/components/Loader';
import priceDetailAPI from '../../../../services/api/priceDetailAPI';

const buttonRef = React.createRef()

class ImportCSVData extends Component {
    state = {
        fileLoaded: false,
        sendingData: false,
        sendSuccess: false,
        sendFail: false,
        errorsInData: {},
    };

    handleOpenDialog = (e) => {
        // Note that the ref is set async, so it might be null at some point 
        this.setState({
            fileLoaded: false,
            sendSuccess: false,
            sendFail: false,
        })
        if (buttonRef.current) {
            buttonRef.current.open(e)
        }
    }

    handleOnFileLoad = (data) => {
        this.setState({
            fileLoaded: true,
            priceData: data,
        })
        console.log('---------------------------')
        console.log("result", data);
        console.log('---------------------------')
    }

    handleOnError = (err, file, inputElem, reason) => {
        this.setState({
            fileLoaded: false,
            sendingData: false,
            sendSuccess: false,
        })
        console.log(err)
    }

    handleOnRemoveFile = (data) => {
        console.log('---------------------------')
        console.log(data)
        this.setState({
            fileLoaded: false,
            sendingData: false,
            sendSuccess: false,
        })
        console.log('---------------------------')
    }

    handleRemoveFile = (e) => {
        // Note that the ref is set async, so it might be null at some point
        this.setState({
            fileLoaded: false,
            sendingData: false,
            sendSuccess: false,
        })
        if (buttonRef.current) {
            buttonRef.current.removeFile(e)
        }
    }

    sendData = () => {
        this.setState({
            sendingData: true,
            sendSuccess: false,
            sendFail: false,
        })

        let data = this.state.priceData.map((element, i) => {
            let e = {};
            for (let key in element.data) {
                e[key] = parseInt(element.data[key].replace("R", "").replace("r", "").trim());
            }
            return e;
        });;


        console.log("payload data", data);
        priceDetailAPI.sendData(data).then(response => {
            console.log(response);
            this.setState({
                sendingData: false,
            })
            if (response.data.errors) {
                this.setState({
                    sendFail: true,
                })
            } else {
                this.setState({
                    sendSuccess: true,
                    errorsInData: response.data.failed,
                })
            }
        }).catch(error => {
            console.log(error);
            this.setState({
                sendingData: false,
                sendFail: true,
            })
        })
    }

    render() {
        const { fileLoaded,
            sendingData,
            sendSuccess,
            sendFail,
            // errorsInData,
         } = this.state;
        return (
            <Widget className="screen-not-widget" title="Import CSV Data" size="wide" screenNotWidget={true}>
                <div className="import-csv vehicle-form form center-both h-100">

                    <CSVReader
                        ref={buttonRef}
                        onFileLoad={this.handleOnFileLoad}
                        onError={this.handleOnError}
                        noClick
                        noDrag
                        config={{ header: true }}
                        onRemoveFile={this.handleOnRemoveFile}
                    >
                        {({ file }) => (
                            <>

                                {!sendingData && sendSuccess && (
                                    <div>
                                        <p className="center-both success-text fall-down-fade-in hover-rise fill-mode-forwards text-center ">
                                            Data uploaded succesfully{' '}
                                            <span class="material-icons center-both">check</span>
                                        </p>
                                        
                                        
                                        {/* {(errorsInData === {}) && (
                                            <p className="center-both fail-text fall-down-fade-in hover-rise fill-mode-forwards text-center ">
                                                but some enteries contain error
                                                <span class="material-icons center-both">error
                                                </span>
                                            </p>
                                        )} */}
                                    </div>)
                                }

                                {sendingData && sendFail && (
                                    <div className="center-both">
                                        <p className="center-both fail-text fall-down-fade-in hover-rise fill-mode-forwards text-center ">
                                            Data not uploaded, something went wrong{' '}
                                        </p>

                                        <button
                                            type='button'
                                            className=" all-vehicle-button hover-rise  rise-up-fade-in fill-mode-forwards"
                                            onClick={this.sendData}
                                        >
                                            retry
            </button>
                                    </div>)
                                }

                                {fileLoaded && !sendingData && !sendFail && !sendSuccess && (<div className="center-both">
                                    <button
                                        type='button'
                                        className=" all-vehicle-button hover-rise  rise-up-fade-in fill-mode-forwards"
                                        onClick={this.sendData}
                                    >
                                        upload
            </button>
                                </div>)}

                                {sendingData && (<div className="center-both">
                                    <Loader />
                                </div>)}

                                {!fileLoaded && (<div className="center-both"><button
                                    type='button'
                                    className=" all-vehicle-button hover-rise  rise-up-fade-in fill-mode-forwards"
                                    onClick={this.handleOpenDialog}

                                >
                                    Browse file
            </button>
                                </div>)}


                                {fileLoaded && (<table class="center-both">
                                    <tbody>
                                        <tr>
                                            <td>
                                                {file && file.name}
                                            </td>

                                            {!sendingData && (
                                                <td>
                                                    {file && file.name &&
                                                        <span className="uncopyable search-button header-button center-both" onClick={this.handleRemoveFile}>
                                                            <i class="material-icons md-light ">close</i></span>

                                                    }
                                                </td>)}

                                        </tr>
                                    </tbody>
                                </table>)}



                            </>

                        )}


                    </CSVReader>



                </div>

            </Widget>
        );
    }
}

export default ImportCSVData;
